import styled from 'styled-components'

const ModalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Wrapper = styled.div`
  > div {
    display: flex;
    align-items: center;

    margin: 0.5rem 0;
  }
`

const Dashboard = styled.div`
  width: 10vw;
  height: 12vh;

  background: ${props => props.theme.colors.white};

  box-shadow: 1px 1px 30px 1px rgba(15, 17, 38, 0.05);
  border-radius: 12px;

  padding: 1rem 0;
  margin-right: 4rem;
`

const Score = styled.span`
  font-size: ${props => props.theme.fontSizes.xxl};
  font-weight: ${props => props.theme.fontWeight.semiBold};
  color: ${props => props.color};
`

const Figures = styled.span`
  color: ${props => props.theme.colors.fucsia};
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeight.semiBold};
`

export {ModalWrapper, Wrapper, Dashboard, Score, Figures}