import React from 'react'

const PaperPlaneIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.0117 13.2366L5.53666 2.89052C5.38123 2.80348 5.20294 2.76595 5.02562 2.78296C4.84829 2.79996 4.68038 2.87068 4.54433 2.98567C4.40827 3.10065 4.31055 3.25443 4.26423 3.42644C4.21791 3.59845 4.22519 3.7805 4.28511 3.94826L7.76991 13.7057C7.83788 13.896 7.83788 14.104 7.76991 14.2943L4.28511 24.0517C4.22519 24.2195 4.21791 24.4016 4.26423 24.5736C4.31055 24.7456 4.40827 24.8993 4.54433 25.0143C4.68038 25.1293 4.84829 25.2 5.02562 25.217C5.20294 25.234 5.38123 25.1965 5.53666 25.1095L24.0117 14.7634C24.1474 14.6874 24.2604 14.5766 24.3391 14.4425C24.4177 14.3083 24.4592 14.1555 24.4592 14C24.4592 13.8445 24.4177 13.6917 24.3391 13.5575C24.2604 13.4234 24.1474 13.3126 24.0117 13.2366V13.2366Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.875 14H14.875" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    )
}

export {PaperPlaneIcon}