import React from 'react'
import {BarChart, Bar, Cell, XAxis, YAxis, Tooltip} from 'recharts'
import { getFormsBySize } from 'pages/Rating/helpers'

const barColors = ["#6C3F98", "#E6148B", "#00B9B4"]

const TinyBarChart = ({chartData, layout = 'horizontal'}) => {
  const tinyBarChartData = getFormsBySize(chartData)

  return (
    <BarChart width={375} height={170} data={tinyBarChartData} layout={layout}>
      <XAxis dataKey="Tamaño" />
      <Tooltip />
      <Bar barSize={20} dataKey="Cantidad" fill={'#E6148B'} radius={[25, 25, 0, 0]}>
      {
        tinyBarChartData?.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={barColors[index]} />
        ))
      }
      </Bar>
    </BarChart>
  )
}

export {TinyBarChart}
   