import * as React from 'react'
import {QueryClient, QueryClientProvider } from 'react-query'
import {AuthProvider} from './context'

const queryClient = new QueryClient()

const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    retry: 0
  },
}

const AppProviders = ({children}) => {
  return (
    <QueryClientProvider client={queryClient} config={queryConfig}>
      <AuthProvider>{children}</AuthProvider>
    </QueryClientProvider>
  )
}

export {AppProviders}
