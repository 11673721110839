import React from 'react'
import {ModalWrapper, Wrapper, Score, Dashboard, Dot} from './styles'
import {Modal, Title, SmallText} from 'shared/components/index'
import {Close} from 'shared/assets/svgs/RatingPageIcons/index'
import {Button} from 'shared/components/Button'
import { getFormsByIndustryType } from '../helpers'
import {BarChart, Bar, Cell, XAxis, YAxis, Tooltip} from 'recharts'
// import {TinyBarChart} from '../components'

const barColors = ["#6C3F98", "#E6148B", "#00B9B4"]

const TinyBarChart = ({chartData, layout = 'horizontal'}) => {
  return (
    <BarChart width={375} height={170} data={chartData}>
      <XAxis dataKey="Año" />
      <Tooltip />
      <Bar barSize={20} dataKey="Puntaje" fill={'#E6148B'} radius={[25, 25, 0, 0]}>
      {
        chartData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={barColors[index]} />
        ))
      }
      </Bar>
    </BarChart>
  )
} 

const SIZES_TYPES = {
  small: 'Pequeña',
  medium: 'Mediana',
  large: 'Grande'
}

const OrganizationDetail = ({isOpenOrgDetailsModal, setIsOpenOrgDetailsModal, org, ranking}) => {
  const organization = {...org}
  // const tinyBarChartData = getFormsByIndustryType(organization?.completedForms)
  const graphData = organization?.completedForms?.map(form => {
    return {
      Año: form.answerCompletionDate.slice(0, 7),
      Puntaje: form.score
    }
  })

  return (
    <>
      <Modal
        isOpen={isOpenOrgDetailsModal}
        toggleModal={setIsOpenOrgDetailsModal}
        width='45rem'
        height='30rem'
        background='#F4F6FA'
      >
        <section style={{width: '100%', height: '100%', margin: '4rem'}}>
          <Button background='transparent' onClick={() => setIsOpenOrgDetailsModal(false)} style={{position: 'absolute', right: '0'}}>
            <Close />
          </Button>
          <Title margin='3rem 0 1rem 0'>{organization.name}</Title>  
          <ModalWrapper>
            <Wrapper>
              <SmallText size='16px' weight='600' color='#6C3F98'>#{ranking}</SmallText>
              <SmallText weight='600' margin='0 1rem'>{organization.answerCompletionDate.slice(0, 10)}</SmallText>
              <SmallText weight='600' margin='0 1rem'>{SIZES_TYPES[organization.size]}</SmallText>
              <SmallText weight='600' margin='0 1rem'>{organization.location}</SmallText>
              <SmallText weight='600' margin='0 1rem'>{organization.type}</SmallText>
            </Wrapper>
          </ModalWrapper>
          <Dashboard>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Dot bg='#00B9B4' />
              <div>
                <Score>
                  {organization.nCompleted}
                </Score>
                <SmallText>
                  Completados
                </SmallText>
              </div>
            </div> 
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Dot bg='#E6148B' />
            <div>
              <Score>
              {organization.nInProgress}
              </Score>
              <SmallText>
                En curso
              </SmallText>
            </div>
          </div> 
        </Dashboard>
        <TinyBarChart 
          chartData={graphData} 
        />
        </section>
      </Modal>
    </>
  )
}

export {OrganizationDetail}