const sortOrgsByRanking = (organizations = []) => {
    const orgsWithRanking = organizations
      ?.sort((a, b) => b.score - a.score)
      .map((org, idx) => {
        return {
          ...org,
          ranking: idx + 1
        }
      }
    )
    return orgsWithRanking
  }

export {sortOrgsByRanking}