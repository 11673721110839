import React from 'react'
import {Modal, Title, SmallText, Subtitle} from 'shared/components/index'
import {useOrganizationById} from 'hooks/organizations'
import {useNavigate} from 'react-router-dom'
import {Button} from 'shared/components/Button'
import {ThreeCircles, Genre, DoubleChecks, Globe, User, Phone } from 'shared/assets/svgs/OrganizationsPageIcons/index'
import { Sector } from 'shared/assets/svgs/index'
import {Mail, Spinner, Department} from 'shared/assets/svgs/index'
import {ModalWrapper, Wrapper, Dashboard, Score, Figures} from './styles'


const OrganizationDetail = ({isOpenOrgDetailsModal, setIsOpenOrgDetailsModal, organization}) => {
  console.log(organization, 'organization')
  return (
    <>
      <Modal
        isOpen={isOpenOrgDetailsModal}
        toggleModal={setIsOpenOrgDetailsModal}
        width='45rem'
        height='fit-content'
      >
        <section style={{width: '100%', margin: '2rem'}}>
          <Title margin='1rem 0'>{organization?.name}</Title>  
          <ModalWrapper>
            <Wrapper>
              <div>
                <ThreeCircles />
                <SmallText margin='0 0.5rem'>{organization?.employeesNumber} empleadxs</SmallText>
              </div>
              <div>
                <Genre />
                <SmallText margin='0 0.5rem'>{organization?.type}</SmallText>
              </div>
              <div>
                <Sector />
                <SmallText margin='0 0.5rem'>{organization?.sector}</SmallText>
              </div>
              <div>
                <DoubleChecks />
                <SmallText margin='0 0.5rem'>{organization?.creationDate ? organization?.creationDate.slice(0, 10) : 'Sin fecha'}</SmallText>
              </div>
              <div>
                <Globe />
                <SmallText margin='0 0.5rem'>{organization?.location}</SmallText>
              </div>
            </Wrapper>
            <Wrapper>
              <div>
                <Mail />
                <SmallText margin='0 0.5rem'>{organization?.representative?.emailAddress}</SmallText>
              </div>
              <div>
                <User />
                <SmallText margin='0 0.5rem'>{organization?.representative?.name}</SmallText>
              </div>
              <div>
                <Department />
                <SmallText margin='0 0.5rem'>{organization?.representative?.department}</SmallText>
              </div>
              <div>
                <Phone />
                <SmallText margin='0 0.5rem'>{organization?.representative?.contactNumber}</SmallText>
              </div>
            </Wrapper>
          </ModalWrapper>

          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Button background='#F9F8FA' color='#3D4852' fontSize='base' padding='1rem 1.75rem' style={{margin: '0 0.25rem'}}
              onClick={() => setIsOpenOrgDetailsModal(false) }
            >
              Volver
            </Button>
          </div>
        </section>
      </Modal>
    </>
  )
}

export {OrganizationDetail}