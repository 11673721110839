import React from 'react'

const GlobeIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3.87402 14.3277L4.78635 13.7769C4.878 13.7216 4.9539 13.6437 5.00678 13.5506C5.05966 13.4575 5.08774 13.3524 5.08834 13.2454L5.10419 10.4228C5.10485 10.3049 5.13886 10.1895 5.2023 10.0901L6.7525 7.6606C6.79832 7.58879 6.85833 7.5271 6.92886 7.47933C6.99938 7.43156 7.07893 7.39871 7.16261 7.38279C7.24629 7.36688 7.33234 7.36824 7.41548 7.38679C7.49862 7.40534 7.57709 7.44068 7.64607 7.49066L9.18168 8.60321C9.31174 8.69744 9.47308 8.73799 9.63224 8.71643L12.0917 8.38336C12.2425 8.36293 12.3807 8.28814 12.4804 8.17303L14.2114 6.17296C14.3165 6.05154 14.3708 5.89434 14.3631 5.73394L14.2718 3.83524" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.6146 15.9127L13.7761 15.0726C13.6977 14.9941 13.5998 14.9378 13.4924 14.9096L11.816 14.4697C11.6672 14.4306 11.538 14.3381 11.4532 14.2097C11.3684 14.0814 11.3339 13.9263 11.3563 13.7741L11.5426 12.5088C11.5583 12.402 11.6014 12.3011 11.6678 12.2159C11.7341 12.1307 11.8213 12.0641 11.921 12.0227L14.3 11.0339C14.4099 10.9882 14.5305 10.9749 14.6477 10.9954C14.7649 11.0159 14.8739 11.0695 14.9617 11.1498L16.9065 12.9286" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
    )
}

export {GlobeIcon}
