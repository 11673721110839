import React from 'react'

const ChatIcon = ({ color }) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.5001 11.3749H17.5001" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.5001 14.8749H17.5001" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.37 21.4248L14.7502 24.1244C14.6725 24.254 14.5625 24.3612 14.4311 24.4357C14.2996 24.5101 14.151 24.5493 13.9999 24.5493C13.8488 24.5493 13.7003 24.5101 13.5688 24.4357C13.4373 24.3612 13.3274 24.254 13.2496 24.1244L11.6298 21.4248C11.5521 21.2952 11.4421 21.188 11.3106 21.1135C11.1791 21.0391 11.0306 21 10.8795 21H4.37494C4.14287 21 3.92031 20.9078 3.75622 20.7437C3.59213 20.5796 3.49994 20.357 3.49994 20.125V6.125C3.49994 5.89294 3.59213 5.67038 3.75622 5.50628C3.92031 5.34219 4.14287 5.25 4.37494 5.25H23.6249C23.857 5.25 24.0796 5.34219 24.2437 5.50628C24.4078 5.67038 24.4999 5.89294 24.4999 6.125V20.125C24.4999 20.3571 24.4078 20.5796 24.2437 20.7437C24.0796 20.9078 23.857 21 23.6249 21L17.1204 21C16.9693 21 16.8207 21.0391 16.6892 21.1135C16.5578 21.188 16.4478 21.2952 16.37 21.4248V21.4248Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
    )
}

export {ChatIcon}