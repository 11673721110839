import React from 'react'

const PencilIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.5 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V12.7589C3.125 12.6768 3.14117 12.5955 3.17258 12.5197C3.20398 12.4439 3.25002 12.375 3.30806 12.3169L12.6831 2.94194C12.8003 2.82473 12.9592 2.75888 13.125 2.75888C13.2908 2.75888 13.4497 2.82473 13.5669 2.94194L17.0581 6.43306C17.1753 6.55027 17.2411 6.70924 17.2411 6.875C17.2411 7.04076 17.1753 7.19973 17.0581 7.31694L7.5 16.875Z" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.625 5L15 9.375" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.8748 16.875H7.49979L3.16455 12.5398" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
    )
}

export {PencilIcon}