import React from 'react'
import styled from 'styled-components'

const Input = ({ 
  name,
  value,
  onChange,
  defaultValue,
  type,
  placeholder,
  bg = '#FFFFFF', 
  hasBorder = false,
  padding,
  width,
  required
}) => {
  return (
    <>
      <StyledInput 
        onChange={onChange}
        defaultValue={defaultValue}
        name={name}
        value={value}
        type={type}
        bg={bg}
        hasBorder={hasBorder}
        placeholder={placeholder}
        padding={padding}
        width={width}
        required={required}
      />
    </>
  )
}

const StyledInput = styled.input`
  display: block;

  width: 100%;
  box-sizing: border-box;

  width: ${props => props.width};
  border: 1px solid rgba(46, 45, 90, 0.68);
  border-radius: 10px;

  padding: ${props => props.padding || '0.5rem'};

  background: ${props => props.bg};

  margin: 1rem 0;

`

export {Input}

