import styled from 'styled-components'

const StyledInput = styled.input`
  width: 100%;

  margin-right: 1rem;

  outline: 0;
  border-width: 0 0 1px;
  border-color: ${props => props.theme.colors.violet};
`

const ErrorMessage = styled.p`
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.error};
  margin-top: 0.5rem;
`

export {StyledInput, ErrorMessage}