const theme = {
  fontSizes: {
    xxs: "9px",
    xs: "12px",
    sm: "14px",
    base: "16px",
    customBase: "18px",
    regular: "20px",
    medium: "22px",
    extended: "24px",
    lg: "30px",
    xlg: "35px",
    xxl: "40px",
    '2xxl': "50px"
  },
  fontWeight: {
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800
  },
  fontFamilies: {
    main: "'Montserrat', sans-serif",
  },
  colors: {
    white: '#FFFFFF',
    violet: '#6C3F98',
    dark: '#3D4852',
    error: '#CC3300',
    gray: 'rgba(46, 45, 90, 0.68)',
    green: '#00B9B4',
    fucsia: '#E6148B',
    backgroundGray: '#F4F6FA',
    lightGray: '#F9F8FA',
  },
  maxWidth: {
    xs: "20rem",
    sm: "24rem",
    md: "28rem",
    lg: "32rem",
    xl: "36rem",
    xxl: "42rem",
    x2xl: "48rem",
  },
};
  
  export {theme};
  