import React from 'react'

const GenreIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 17.1875C13.9695 17.1875 17.1875 13.9695 17.1875 10C17.1875 6.03045 13.9695 2.8125 10 2.8125C6.03045 2.8125 2.8125 6.03045 2.8125 10C2.8125 13.9695 6.03045 17.1875 10 17.1875Z" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10 2.8125V5.9375" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M2.8125 10H5.9375" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10 17.1875V14.0625" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.1875 10H14.0625" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    )
}

export {GenreIcon}