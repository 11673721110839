import React, {useState} from 'react'
import styled from 'styled-components'
import {ErrorBoundary} from 'react-error-boundary'
import {Wrapper, Bar, LiWrapper, ModuleText} from './styles'
import {Badge, Button, ListItem, ErrorFallback} from 'shared/components/index'
import {Select, Search, Title} from '../../shared/components/index'
import {Pencil, Spinner, Trash} from '../../shared/assets/svgs/index'
import {useRemoveQuestion, useAddQuestionByFormId, useEditQuestion, useFormularies, useFormularyById, useAddNewFormulary, useEditFormularyStatus} from '../../hooks/formularies'
import {Question} from './Question'
import {useQuestions} from './hooks/use-questions'
import {BADGE_TYPES_COLORS, BADGE_TYPES_NAMES} from './types'
import {useMutaterQuestion} from './Question/reducers/question-reducer'
import {questionMethods} from './api'
import {usePaginator} from 'hooks/use-paginator'
import {Modal} from '../../shared/components/index'
import {ErrorMessage} from 'pages/Login/styles'

const defaultState = {
  id: undefined,
  title: '',
  type: '',
  module: 'Compromiso',
  recommendationThreshold: 0,
  options: [],
  recommendations: []
}

const Formularies = ({formularies}) => {
  const [openModalType, setOpenModalType] = useState('')
  const [formId, setFormId] = useState(formularies[0]?.id)

  const [error, setError] = useState(false)
  const [query, setQuery] = useState('')
  const [errors, setErrors] = useState('')
  const [formName, setFormName] = useState('')
  const [editQuestionFields, setEditQuestionFields] = useState({})
    
  const [questionFound, setQuestionFound] = useState(null)

  const {data: formulary, isLoading} = useFormularyById(formId)
  const {questions, setQuestions} = useQuestions(formulary?.form)

  const {mutate: add} = useAddQuestionByFormId(formId)
  const {mutate: remove} = useRemoveQuestion(formId, questionFound?.id)
  const {mutate: edit} = useEditQuestion(formId, questionFound?.id)

  const actionType = questionFound ? 'edit' : 'add'
  const {state, change} = useMutaterQuestion(questionFound ? questionFound : defaultState)

  const lowercasedFilter = query.toLowerCase();
  const filteredData = questions?.filter(qc => {
    return  qc.title.toLowerCase().includes(lowercasedFilter.toLowerCase()) ||
            qc.module.toLowerCase().includes(lowercasedFilter.toLowerCase())
  })
  
  const {items, pageNumber, pagesList, setPageNumber} = usePaginator(filteredData && filteredData, 10)

  const questionApi = questionMethods()
  const ACTIONS_ON_QUESTION = {
    add: {
      action: (e) => questionApi.add(e, state, setError, add, setOpenModalType, setErrors),
      text: 'Agregar pregunta'
    },
    edit: {
      action: (e) => questionApi.edit(e, editQuestionFields, edit, setOpenModalType, setErrors),
      text: 'Editar pregunta'
    },
    remove: {
      action: (e, id) => questionApi.remove(e, id, remove, setQuestions, setErrors)
    }
  }
  
  const openAddQuestionModal = () => {
    setOpenModalType('add')
    change('add', defaultState)
  }

  const findQuestion = (questionId) => {
    const questionsCopy = [...questions]
    const findQuestion = questionsCopy.find(qc => qc.id === questionId)
    change('edit', findQuestion)
    setQuestionFound(findQuestion)
    setOpenModalType('edit')
  }

  const {mutate: createFormulary} = useAddNewFormulary()
  const {mutate: editFormularyStatus} = useEditFormularyStatus(formId)
  const handleCreateForm = () => {
    if(!formName) {
      setErrors('empty-form-name')
      return
    }
    setOpenModalType('')
    createFormulary({name: formName})
  }

  const publishForm = () => {
    editFormularyStatus({status: 'published'})
  }
  
  return (
    <>
    <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1rem'}}>
      <div style={{display: 'flex'}} >
        <Select 
          name='formulary'
          border='none'
          width='50%'
          margin='0 1rem 0 0'
          onChange={(e) => setFormId(e.target.value)}
        >
          <option disabled style={{fontStyle: 'italic'}}>Nuevo formulario</option>
          {!formularies
            ? <Spinner />
            : formularies?.map(form => (
                <option 
                  key={form.id} 
                  value={form.id}
                >
                  {form.name}
                </option>
            )) 
          }
            
        </Select>
        <Button
          background='white'
          color='#3D4852'
          fontSize='sm'
          fontWeight='bold'
          padding='1rem 0.5rem'
          onClick={() => setOpenModalType('new-form')}
        >
          + Nuevo formulario
        </Button>
      </div>
      <Modal
        isOpen={openModalType === 'new-form'}
        toggleModal={setOpenModalType}
        width='30rem'
        height='20rem'
      >
        <ModalWrapper>
          <Title>Nuevo formulario</Title>
          <div style={{display: 'flex', flexDirection: 'column', margin: '1rem 6rem'}}>
            <input 
              style={{marginBottom: '1rem', padding: '0.75rem', border: '0.5px solid #2E2D5A', borderRadius: '10px'}} 
              type='text' 
              placeholder='Nombre del formulario'
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
            />
          </div>
          <Button 
            style={{margin: '0 auto'}}
            fontSize='sm' 
            px='xl' 
            leftIconSpacing='12'
            padding='1rem 0.5rem'
            onClick={() => handleCreateForm()}
          >
            Crear formulario
          </Button>
          {errors === 'empty-form-name' ? <ErrorMessage>Nombre de formulario vacio. Completar el campo para crearlo.</ErrorMessage> : null}
        </ModalWrapper>
      </Modal>
      <div style={{display: 'flex'}}>
        <Button
          background='#E6148B'
          color='#FFFFFF'
          fontSize='sm'
          fontWeight='semibold'
          padding='0 1rem'
          style={{margin: '0 1rem'}}
          onClick={() => publishForm()}
          disabled={formulary?.form?.status === 'published'}
        >
          + Publicar
        </Button>
      </div>
    </div>

    {formularies?.length === 0
      ? <div style={{display: 'flex', justifyContent: 'center', height: '100vh'}}>Aún no tienes formularios creados.</div>
      : <Wrapper>
        {errors === 'something-went-wrong' 
          ? <div>Oops, algo salio mal. Por favor refresca la pagina e intenta nuevamente.</div>
          : <div>
              <Bar>
                <Badge 
                  style={{display: 'flex', alignItems: 'center', marginRight: '1rem'}}
                  background={BADGE_TYPES_COLORS['published'].background}
                  color={BADGE_TYPES_COLORS['published'].color}
                >
                {isLoading ? <span>Loading...</span> : <span style={{textAlign: 'center'}}>{BADGE_TYPES_NAMES[formulary?.form?.status]}</span>} 
                </Badge>
                <Search
                  width='70%'
                  placeholder='Buscar palabra relacionada con la pregunta'
                  isSearch={true}
                  bg='#F9F8FA'
                  padding='1rem'
                  id='search'
                  name='search'
                  onChange={(e) => setQuery(e.target.value)}
                />
                <Button
                  background='#F9F8FA'
                  color='#3D4852'
                  fontSize='sm'
                  fontWeight='bold'
                  padding='1rem 0'
                  style={{width: '25%', marginLeft: '2rem'}}
                  disabled={formulary?.form?.status === 'published'}
                  onClick={() => openAddQuestionModal()}
                >
                  + Agregar pregunta
                </Button>
              </Bar>
              {/* {isLoading 
                ? <div>Loading...</div> */}
                <ul style={{padding: '0'}}>
                    {items?.map((question, idx) => (
                      // isLoading 
                      // ? <div>Borrando...</div>
                      <LiWrapper key={question.id} style={{borderBottom: '1px solid rgba(46, 45, 90, 0.05)'}}>
                          <div style={{display: 'flex', alignItems: 'center', minWidth: '70%'}}>
                            <span>
                              {question.order}
                            </span>
                            <ListItem 
                              padding='1rem 0'
                              margin='0 2rem'
                              fontSize='sm'
                              color='#3D4852'
                            >
                              {question.title}
                            </ListItem>
                          </div>
                          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', minWidth: '30%'}}>
                            <ModuleText>
                              {question.module}
                            </ModuleText>
                            <div style={{display: 'flex'}}>
                              <Button
                                background='#F9F8FA'
                                border='full'
                                padding='0.75rem'
                                margin='0 0.25rem'
                                disabled={formulary?.form?.status === 'published'}
                                onClick={() => findQuestion(question.id)}
                              > 
                                <Pencil />
                              </Button>
                              <Button
                                background='#F9F8FA'
                                border='full'
                                padding='0.75rem'
                                margin='0 0.25rem'
                                onClick={(e) => ACTIONS_ON_QUESTION['remove'].action(e, question.id)}
                                // disabled={formulary?.form?.status === 'published'}
                              > 
                                <Trash />
                              </Button>
                            </div>
                          </div>
                          
                      </LiWrapper>                               
                    ))}
                </ul>         
              {/* } */}
            </div>
        }
        
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
          {pagesList.map(lof => (
            <Badge
              key={lof}
              background='rgba(108, 63, 152, 0.2)'
              color='#6C3F98'
              fontWeight='bold'
              onClick={() => setPageNumber(lof)}
              style={{cursor: 'pointer', textAlign: 'center'}}
            >
              {lof}
            </Badge>
          ))}
        </div>
        
        <Question 
          openModalType={openModalType} 
          setOpenModalType={setOpenModalType}
          change={change}
          questionAction={(e) => ACTIONS_ON_QUESTION[openModalType]?.action(e)}
          fields={state}
          actionText={ACTIONS_ON_QUESTION[openModalType]?.text}
          errors={errors}
          setErrors={setErrors}
          setQuestionFound={setQuestionFound}
          actionType={actionType}
          setEditQuestionFields={setEditQuestionFields}
        />

      </Wrapper>
    }
    </>
)
}

const ModalWrapper = styled.div`
  width: 100%;
  text-align: center;
  display: block;
`

export {Formularies}