import styled from 'styled-components'

const FormWrapper = styled.div`
  width: 30vw;
  margin-top: 2rem;
  padding-top: 1rem;
`

const Wrapper = styled.div`
  display: flex;
`

const Dashboard = styled.div`
  width: 10vw;
  height: 10vh;

  background: ${props => props.theme.colors.white};

  box-shadow: 1px 1px 30px 1px rgba(15, 17, 38, 0.05);
  border-radius: 12px;

  padding: 2rem;
  margin-right: 2rem;
`

const Score = styled.span`
  font-size: ${props => props.theme.fontSizes.xxl};
  font-weight: ${props => props.theme.fontWeight.semiBold};
  color: ${props => props.color};
`

const Text = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeight.semiBold};
  color: ${props => props.theme.colors.gray};
`

export {FormWrapper, Wrapper, Dashboard, Score, Text}