import React from 'react'
import {Modal, Title, SmallText, Subtitle} from 'shared/components/index'
import {useOrganizationById} from 'hooks/organizations'
import {useNavigate} from 'react-router-dom'
import {Button} from 'shared/components/Button'
import {ThreeCircles, Genre, DoubleChecks, Globe, User, Phone } from 'shared/assets/svgs/OrganizationsPageIcons/index'
import {Mail, Spinner} from 'shared/assets/svgs/index'
import {ModalWrapper, Wrapper, Dashboard, Score, Figures} from './styles'
import {useOrganizationProgress} from 'hooks/organizations'


const OrganizationDetail = ({isOpenOrgDetailsModal, setIsOpenOrgDetailsModal, organization}) => {
  const navigate = useNavigate()
  const {organizationProgress, isOrganizationProgressLoading} = useOrganizationProgress(organization?.id)

  if(isOrganizationProgressLoading) {
    return <Spinner />
  }

  const totalProgress = () => {
    const progress = organizationProgress?.lastPublishedForm?.modules?.reduce((acc, el) => acc += el.percentageCompleted, 0)
    const modulesAmount = organizationProgress?.lastPublishedForm?.modules.length
    const total = progress / modulesAmount

    return total ? total.toFixed(2) : 0
  }

  return (
    <>
      <Modal
        isOpen={isOpenOrgDetailsModal}
        toggleModal={setIsOpenOrgDetailsModal}
        width='45rem'
        height='30rem'
      >
        <section style={{width: '100%', height: '100%', margin: '4rem'}}>
          <Title margin='3rem 0 1rem 0'>{organization?.name}</Title>  
          <ModalWrapper>
            <Wrapper>
              <div>
                <ThreeCircles />
                <SmallText margin='0 0.5rem'>{organization?.employeesNumber} empleadxs</SmallText>
              </div>
              <div>
                <Genre />
                <SmallText margin='0 0.5rem'>{organization?.type}</SmallText>
              </div>
              <div>
                <DoubleChecks />
                <SmallText margin='0 0.5rem'>{organization?.creationDate ? organization?.creationDate.slice(0, 10) : 'Sin fecha'}</SmallText>
              </div>
              <div>
                <Globe />
                <SmallText margin='0 0.5rem'>{organization?.location}</SmallText>
              </div>
            </Wrapper>
            <Wrapper>
              <div>
                <Mail />
                <SmallText margin='0 0.5rem'>{organization?.representative?.emailAddress}</SmallText>
              </div>
              <div>
                <User />
                <SmallText margin='0 0.5rem'>{organization?.representative?.name}</SmallText>
              </div>
              <div>
                <Phone />
                <SmallText margin='0 0.5rem'>{organization?.representative?.contactNumber}</SmallText>
              </div>
            </Wrapper>
          </ModalWrapper>

          <div style={{display: 'flex', justifyContent: 'space-evenly', margin: '0 0 1rem 0'}}>
            <div>
              <Subtitle>Formularios</Subtitle>
              <Dashboard>
                <Score color='#00B9B4'>
                  {/* {organization?.forms_completed ? organization?.forms_completed : 0 } */}
                  {organizationProgress?.nCompleted}
                </Score><br />
                <SmallText>
                  Completados
                </SmallText>
              </Dashboard>
            </div>
            <div>
              <Subtitle>Detalle</Subtitle>
              <Dashboard>
                {organizationProgress?.lastPublishedForm?.modules?.map(module => (
                  <div style={{marginBottom: '0.5rem', display: 'flex', justifyContent: 'space-between'}}>
                    <SmallText weight='600'>
                      {module.name}:
                    </SmallText>
                    <Figures>
                      {module.percentageCompleted.toFixed(2)}%
                    </Figures>
                  </div>
                ))}
                <div style={{marginBottom: '0.5rem', display: 'flex', justifyContent: 'space-between'}}>
                  <SmallText weight='600'>
                    Total completado:
                  </SmallText>
                  <Figures>
                    {totalProgress()}%
                  </Figures>
                </div>
              </Dashboard>
            </div>
            <div>
              <Subtitle>Ranking #</Subtitle>
              <Dashboard>
                <Score color='#6C3F98'>
                  {/* 5 */}
                  {organizationProgress?.lastPublishedForm?.ranking 
                    ? organizationProgress?.lastPublishedForm?.ranking 
                    : <SmallText>Sin ranking</SmallText>
                  }
                </Score><br />
                <SmallText>
                  {organizationProgress?.lastPublishedForm?.score 
                    ? `${parseInt(organizationProgress?.lastPublishedForm?.score)} puntos`
                    : 'Sin puntaje'} 
                  {/* 20 */}
                </SmallText>
              </Dashboard>
            </div>
          </div>

          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Button background='#6C3F98' color='#FFFFFF' fontSize='base' padding='0.75rem 1.5rem' style={{margin: '0 0.25rem'}}
              onClick={() => navigate(`/organizaciones/edit/${organization.id}`)}
            >
              Editar
            </Button>
            <Button background='#F9F8FA' color='#3D4852' fontSize='base' padding='0.75rem 1.5rem' style={{margin: '0 0.25rem'}}
              onClick={() => setIsOpenOrgDetailsModal(false) }
            >
              Cancelar
            </Button>
          </div>
        </section>
      </Modal>
    </>
  )
}

export {OrganizationDetail}