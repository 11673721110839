import React from 'react';
import styled from 'styled-components';

const Badge = ({
  children, 
  background='transparent', 
  fontSize = 'xs', 
  fontWeight = '500',
  color = '#E6148B', 
  margin='2px 2px', 
  padding = '8px 24px', 
  border='none',
  borderRadius = '24px',
  style,
  onClick
}) => {
  return (
    <StyledBadge
      background={background}
      color={color}
      padding={padding}
      margin={margin} 
      border={border}
      borderRadius={borderRadius}
      fontSize={fontSize}
      fontWeight={fontWeight}
      style={style}
      onClick={onClick}
    >
      {children}
    </StyledBadge>
  )
}

const StyledBadge = styled.span`
  // display: flex;
  // justify-content: center;
  // align-items: center;

  padding: ${props => props.padding ? props.padding : ' 0.75rem 0;'};
  margin: ${props => props.margin};

  color: ${props => props.color};
  background: ${props => props.background};

  font-size: ${props => props.theme.fontSizes[props.fontSize]};
  font-weight: ${props => props.fontWeight};
  text-align: center;

  border: ${props => props.border};
  border-radius: ${props => props.borderRadius};

  cursor: pointer;
`

export {Badge}