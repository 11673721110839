import React, {useState, useEffect} from 'react'

const useQuestions = (formulary) => {
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    setQuestions(formulary?.questions)
  }, [formulary?.questions])

  return {
    questions,
    setQuestions
  }
}

export {useQuestions}