const SIZE_TYPES = {
  small: 'Pequeñas',
  medium: 'Medianas',
  large: 'Grandes'
}

const getFormsBySize = (data) => {
  const sizes = data?.map(obj => {
      return {
        Tamaño: SIZE_TYPES[obj.name],
        Cantidad: obj.n
      }
  })
  return sizes
}

export {getFormsBySize}