import * as React from 'react'
import * as auth from './provider'
import {useAsync} from 'hooks/use-async'
import {Spinner} from 'shared/assets/svgs/index'

const me = async () => {}

const AuthContext = React.createContext()
AuthContext.displayName = 'AuthContext'

const AuthProvider = (props) => {
  const {
    data: user,
    status,
    isLoading,
    isIdle,
    isSuccess,
    run,
    setData,
  } = useAsync()

  React.useEffect(() => {
    const appDataPromise = me()
    run(appDataPromise)
  }, [run])

  const login = React.useCallback(
    form => auth.login('login', form).then(user => setData(user)),
    [setData],
  )

  const logout = React.useCallback(() => {
    auth.logout()
    setData(null)
  }, [setData])

  const value = React.useMemo(
    () => ({user, login, logout}),
    [login, logout, user],
  )

  if (isLoading || isIdle) {
    return <Spinner width='38' height='38' isFull />
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export {AuthProvider, useAuth}
