
import {useState} from 'react'

const usePaginator = (arr = [], size = 10) => {
  const [pageNumber, setPageNumber] = useState(1)

  const arrCopy = [...arr]
  const mappedArrCopy = arrCopy.map((el, idx) => {
    return {
      ...el,
      order: idx + 1
    }
  })
  const pages = Math.ceil(mappedArrCopy.length / size)

  return {
    items: mappedArrCopy.slice((pageNumber - 1) * size, pageNumber * size),
    pages,
    pagesList: [...Array(pages)].map((_, i) => i + 1),
    pageNumber,
    setPageNumber
  }
}                   
  
  export {usePaginator}