import {Title, Subtitle, Button} from 'shared/components'
import {useNavigate} from 'react-router-dom'
import { useResetOrganizationPassword } from 'hooks/organizations'

const PasswordChangeWarning = ({setIsOpenResetPasswordModal, organization}) => {
  const {mutate} = useResetOrganizationPassword(organization?.id)
  const navigate = useNavigate()
  const handleAction = () => {
    mutate({id: organization?.id})
    setIsOpenResetPasswordModal(false)
    navigate('/organizaciones')
  }
  return (
    <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
      <div style={{textAlign: 'center', margin: '1rem'}}>
        <Title textAlign='center' margin='1rem 0' style={{display: 'flex', justifyContent: 'center'}}>
          ¡Atención! Resetearás la contraseña de la organización <span style={{color: '#00B9B4'}}>{organization.name}</span>
        </Title>
        <Subtitle margin='2rem' weight='400'>
          Esta acción enviará al email de la organización una nueva contraseña.
        </Subtitle>
        <Subtitle margin='2rem' weight='400'>
          ¿Está segure de que desea continuar?
        </Subtitle>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Button 
            background='#E6148B' fontSize='sm' fontWeight='bold'
            color='white'
            borderRadius='10px'
            type='submit'
            style={{margin: '1rem', padding: '0.75rem 1.25rem'}}
            onClick={() => handleAction()}
          >
            Sí, resetear contraseña
          </Button>
          <Button 
            background='#00B9B4' fontSize='sm' fontWeight='bold'
            color='white'
            borderRadius='10px'
            type='submit'
            style={{margin: '1rem', padding: '0.75rem 1.25rem'}}
            onClick={() => setIsOpenResetPasswordModal(false)}
          >
            No, volver
          </Button>
        </div>
      </div>
    </div>
  )
}

export {PasswordChangeWarning}