import React from 'react'

const PowerIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.9991 5.25031V13.5628" stroke="#3D4852" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.2502 5.9317C20.9892 7.06348 22.3159 8.72691 23.0326 10.6741C23.7493 12.6212 23.8176 14.7478 23.2273 16.7369C22.637 18.726 21.4197 20.4712 19.7569 21.7122C18.0941 22.9532 16.0748 23.6237 14 23.6237C11.9251 23.6237 9.90582 22.9532 8.24303 21.7122C6.58024 20.4711 5.36299 18.726 4.77272 16.7369C4.18244 14.7478 4.25074 12.6212 4.96743 10.674C5.68412 8.72688 7.01083 7.06345 8.74983 5.93168" stroke="#3D4852" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>        
    )
}

export {PowerIcon}