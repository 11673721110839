import {useReducer} from 'react'

// ik these things returns the same but it's just for prototyping purposes. take in count that
// i'm just mutating objects and playing around with them since we don't have a backend yet.
const mutateQuestionReducer = (state, action) => {
  switch (action.type) {
    case 'add': {
      return {
        ...state,
        ...action.payload
      }
    }
    case 'edit': {
      return {
        ...state,
        ...action.payload
      }
    }
    default: {
      throw new Error(`Unsupported type: ${action.type}`)
    }
  }
}

const useMutaterQuestion = (initialState) => {
  const [state, dispatch] = useReducer(mutateQuestionReducer, initialState)
  const change = (actionType, payload) => dispatch({type: actionType, payload: payload})

  return {
    state,
    change,
  }
}

export {useMutaterQuestion}