import React, {useState, useReducer} from 'react'
import styled from 'styled-components'
import {useNavigate, useParams} from 'react-router-dom'
import {
  useOrganizationById,
  useUpdateOrganizations,
  useLookups,
  useLookupsWithOrganizations
} from 'hooks/organizations'
import {Button} from 'shared/components/Button'
import {Label, Input, Select, Badge} from 'shared/components/index'
import {editOrgsReducer} from './reducer'
import {Spinner} from 'shared/assets/svgs'
import {Modal} from 'shared/components/index'
import {EmailChangeWarning} from './EmailChangeWarning'
import {PasswordChangeWarning} from './PasswordChangeWarning'

const EditOrganization = () => {
  const navigate = useNavigate()
  const [isOpenEmailChangeModal, setIsOpenEmailChangeModal] = useState(false)
  const [isOpenResetPasswordModal, setIsOpenResetPasswordModal] = useState(false)
  const {id} = useParams()
  const {data: organization, isOrganizationLoading} = useOrganizationById(id)

  const {data: departments, status: isDepartmentsLoading} = useLookupsWithOrganizations('departments')
  const {data: locations, status: isLocationsLoading} = useLookups('locations')
  const {data: types, status: isTypesLoading} = useLookupsWithOrganizations('types')
  const {data: pronouns, status: isPronounsLoading} = useLookups('pronouns')
  const {data: sectors, status: isSectorsLoading} = useLookupsWithOrganizations('sectors')

  const [state, dispatch] = useReducer(editOrgsReducer, {})
  const change = (field, value) => dispatch({type: 'mutate', field, payload: value})

  const {mutate} = useUpdateOrganizations(organization?.id)
  const handleSubmit = (e) => {
    e.preventDefault()
    
    if(!!state.representative?.emailAddress) {
      setIsOpenEmailChangeModal(true)
    } else {
      if(Object.keys(state).length === 0) {
        navigate('/organizaciones')
      } else {
        navigate('/organizaciones')
        mutate(state)
      }

    }
  }

  if(isLocationsLoading || isPronounsLoading || isTypesLoading || isDepartmentsLoading || isSectorsLoading || isOrganizationLoading) {
    return <Spinner />
  }
  
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Wrapper>
          <div>
            <Label htmlFor='organization-name'>Nombre organización</Label>
            <Input 
              key={organization?.name}
              name='organization-name' 
              type='text'
              defaultValue={organization?.name}
              onChange={(e) => change('name', e.target.value)}
            />
          </div>
          <div>
            <Label htmlFor='types'>Tipo de industria</Label>
            <Select 
              name='types'
              value={state.type ? state.type : organization?.type}
              onChange={(e) => change('type', e.target.value)}
            >
              {types?.organizationTypes.map(type => (
                <option 
                  key={type.name} 
                  value={type.name}
                >
                  {type?.name}
                </option>
              ))}      
            </Select>
          </div>
        </Wrapper>

        <Wrapper>
          <div>
            <Label htmlFor='locations'>País</Label>
            <Select 
              name='locations'
              value={state.location ? state.location : organization?.location}
              onChange={(e) => change('location', e.target.value)}
            >
              {locations?.locations.map(location => (
                <option 
                  key={location.name} 
                  value={location.name}
                >
                  {location.name}
                </option>
              ))} 
            </Select>
          </div>
        </Wrapper>

        <Wrapper>
          <div style={{width: '42.5%'}}>
            <Label htmlFor='employeesNumber'>Cantidad de empleadxs</Label>
            <Input 
              key={organization?.employeesNumber}
              name='employeesNumber' 
              type='number'
              defaultValue={organization?.employeesNumber}
              onChange={(e) => change('employeesNumber', parseInt(e.target.value))}
            />
          </div>
          <div>
                <Label htmlFor='types'>Sector</Label>
                <Select 
                name='sectors'
                value={state?.sector ? state.sector : organization?.sector}
                onChange={(e) => change('sector', e.target.value)}
                >
                {sectors?.organizationSectors?.map(sector => (
                    <option 
                    key={sector.name} 
                    value={sector.name}
                    >
                    {sector?.name}
                    </option>
                ))}      
                </Select>
            </div>
        </Wrapper>

        <Wrapper>
          <div>
            <Label htmlFor='accessionLetter'>Carta firmada de adhesión</Label>
            <Input 
              required
              name='accessionLetter' 
              type='text'
              key={organization?.accessionLetter}
              defaultValue={organization?.accessionLetter}
              onChange={(e) => change('accessionLetter', e.target.value)}
            />
          </div>
        </Wrapper>

        <Wrapper>
          <div>
            <Label htmlFor='representative'>Responsable plataforma</Label>
            <Input 
              key={organization?.representative?.name}
              name='representative' 
              type='text'
              defaultValue={organization?.representative?.name}
              onChange={(e) => change('representative', {...state?.representative, name: e.target.value})}
            />
          </div>
          <div>
            <Label htmlFor='pronounce'>Pronombre</Label>
            <Select 
              name='pronoun'
              value={state.representative?.pronoun ? state.representative.pronoun : organization?.representative?.pronoun}
              onChange={(e) => change('representative', {...state?.representative, pronoun: e.target.value})}
            >
              {pronouns?.pronouns.map(pronoun => (
                <option 
                  key={pronoun.name} 
                  value={pronoun.name} 
                >
                  {pronoun.name}
                </option>
              ))} 
            </Select>
          </div>
        </Wrapper>

        <Wrapper>
          <div>
            <Label htmlFor='contact'>Contacto</Label>
            <Input 
              key={organization?.representative?.emailAddress}
              name='contact' 
              type='text'
              defaultValue={organization?.representative?.emailAddress}
              onChange={(e) => change('representative', {...state?.representative, emailAddress: e.target.value})}
            />
          </div>
          <div>
            <Label htmlFor='phone'>Teléfono</Label>
            <Input 
              key={organization?.representative?.contactNumber}
              name='phone' 
              type="number" 
              pattern=".{10,10}"
              defaultValue={organization?.representative?.contactNumber}
              onChange={(e) => change('representative', {...state?.representative, contactNumber: e.target.value})}
            />
          </div>
        </Wrapper>

        <Wrapper>
          <div style={{width: '42.5%'}}>
            <Label htmlFor='operationArea'>Área</Label>
            <Select 
              name='operationArea'
              value={state?.department ? state?.department : organization?.representative?.department }
              onChange={(e) => change('department', e.target.value)}
            >
              {departments?.organizationDepartments.map(department => (
                <option 
                  key={department.name} 
                  value={department.name}
                >
                  {department.name}
                </option>
              ))} 
            </Select>
          </div>
        </Wrapper>

        <Wrapper>
          <Button 
            background='#00B9B4' fontSize='sm' fontWeight='bold' padding='0.75rem' style={{margin: '0.25rem'}}
            type='submit'
          >
            Guardar
          </Button>
          <Button 
            bg='#FFFFFF' fontSize='sm' fontWeight='bold' padding='0.75rem' style={{margin: '0.25rem'}}
            onClick={() => navigate('/organizaciones')}
          >
            Cancelar
          </Button>
          <Badge 
            background='#E6148B' color='white' fontSize='sm' fontWeight='bold' borderRadius='12px' padding='0.75rem' style={{margin: '0.25rem 4rem'}}
            onClick={() => setIsOpenResetPasswordModal(true)}
          >
            Resetear contraseña
          </Badge>
        </Wrapper>
      </form>

      {
        isOpenEmailChangeModal &&
          <Modal 
            isOpen={isOpenEmailChangeModal}
            toggleModal={setIsOpenEmailChangeModal}
            width='30rem'
            height='25rem'
            justifyCont='flex-start'
            alignItems='flex-start'
          >
          <EmailChangeWarning
            setIsOpenEmailChangeModal={setIsOpenEmailChangeModal} 
            mutate={mutate}
            state={state}
            organization={organization}
          />
          </Modal>
      }

      {
        isOpenResetPasswordModal &&
          <Modal 
            isOpen={isOpenResetPasswordModal}
            toggleModal={setIsOpenResetPasswordModal}
            width='30rem'
            height='25rem'
            justifyCont='flex-start'
            alignItems='flex-start'
          >
          <PasswordChangeWarning
            setIsOpenResetPasswordModal={setIsOpenResetPasswordModal} 
            organization={organization}
          />
          </Modal>
      }
      
    </>
  )
}

const Wrapper = styled.main`
  display: flex;

  width: 80%;

  > div {
    width: 100%;
    margin-right: 4rem;
  }
`

export {EditOrganization}