import styled from 'styled-components'
import {Link} from 'react-router-dom'

const Wrapper = styled.main`
  background: ${props => props.theme.colors.white};
  border: 1px solid transparent;
  border-radius: 20px;

  /* margin: 4rem; */

  > div {
    margin: 2rem;
  }
`

const Bar = styled.div`
  display: flex;
`

const LiWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 42px;
  height: 42px;

  background: ${props => props.theme.colors.lightGray};

  border: transparent;
  border-radius: 50%;

  margin: 0 0.5rem;

  cursor: pointer;
`

export {Wrapper, Bar, LiWrapper, StyledLink}