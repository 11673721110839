import React from 'react'
import styled from 'styled-components'

const Title = ({children, margin = 0, fontSize = 'extended', fontWeight = '600'}) => {
  return (
    <StyledTitle 
      fontSize={fontSize} 
      margin={margin}
      fontWeight={fontWeight}
    >
      {children}
    </StyledTitle>
  )
}

const StyledTitle = styled.h1`
font-size: ${props => props.theme.fontSizes[props.fontSize]};
font-weight: ${props => props.theme.fontWeight[props.fontWeight]};

color: ${props => props.theme.colors.gray};

margin: ${props => props.margin};
`

export {Title}