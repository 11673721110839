import React from 'react'

const DesktopIcon = ({ color }) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.25 5.25H5.25V12.25H12.25V5.25Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M22.75 5.25H15.75V12.25H22.75V5.25Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.25 15.75H5.25V22.75H12.25V15.75Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M22.75 15.75H15.75V22.75H22.75V15.75Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    )
}

export {DesktopIcon}