import React from 'react'
import {SmallText} from 'shared/components/SmallText'
import {Badge} from 'shared/components'

const Type = ({fieldsType, change, actionType, setEditQuestionFields}) => {
  const handleInput = (type) => {
    change(actionType, {type})
    setEditQuestionFields(prevState => ({...prevState, type}))
  }
  return (
    <>
      <SmallText>Tipo de respuesta:</SmallText>
      <div style={{display: 'flex', margin: '0 1rem'}}>
        <Badge
          background='rgba(108, 63, 152, 0.2)'
          color='#6C3F98'
          margin='0 0.25rem'
          fontWeight={fieldsType === 'single-choice' ? 'bold' : 'unset'}
          onClick={() => handleInput('single-choice')}
          style={{cursor: 'pointer', textAlign: 'center'}}
          >
            Selección única
        </Badge>
        <Badge
          background='rgba(108, 63, 152, 0.2)'
          color='#6C3F98'
          fontWeight={fieldsType === 'multiple-choice' ? 'bold' : 'unset'}
          margin='0 0.25rem'
          onClick={() => handleInput('multiple-choice')}
          style={{cursor: 'pointer', textAlign: 'center'}}
        >
            Selección múltiple
        </Badge>
        <Badge
          background='rgba(108, 63, 152, 0.2)'
          color='#6C3F98'
          fontWeight={fieldsType === 'text' ? 'bold' : 'unset'}
          margin='0 0.25rem'
          onClick={() => handleInput('text')}
          style={{cursor: 'pointer', textAlign: 'center', display: 'flex', alignItems: 'center'}}
          >
            Texto
        </Badge>
      </div>
    </>
  )
}

export {Type}