import React from 'react'
import styled from 'styled-components'

const Search = ({ 
  name,
  value,
  defaultValue,
  type,
  hasBorder = false,
  padding,
  width,
  onChange,
  placeholder, 
  bg = '#FFFFFF',
}) => {
  return (
    <>
      <StyledInput 
        name={name}
        defaultValue={defaultValue}
        value={value}
        type={type}
        width={width}
        onChange={onChange}
        bg={bg}
        hasBorder={hasBorder}
        placeholder={placeholder}
        padding={padding}
      />
    </>
  )
}

const StyledInput = styled.input`
  display: block;
  
  width: ${props => props.width};
  border: none;
  border-radius: 10px;

  padding: 1rem;

  background: ${props => props.bg};

  &:focus-visible {
    outline: none;
  }
`

export {Search}

