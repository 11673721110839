import React from 'react'

const Sector = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.00024 12H29.0002L16.0002 4L3.00024 12Z" stroke="#2E2D5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.00024 12V22" stroke="#2E2D5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.0002 12V22" stroke="#2E2D5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.0002 12V22" stroke="#2E2D5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.0002 12V22" stroke="#2E2D5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.00024 22H28.0002" stroke="#2E2D5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.00024 26H30.0002" stroke="#2E2D5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    )
}

export {Sector}