const BADGE_TYPES_COLORS = {
    published: {
      background: 'rgba(230, 20, 139, 0.2)',
      color: '#E6148B'
    }
  }
    
  const BADGE_TYPES_NAMES = {
    published: 'Publicado',
    'in-progress': 'En progreso'
  }
  
  const MODAL_QUESTION_TYPES = {
    edit: 'edit',
    delete: 'delete',
    add: 'add'
  }

  const INPUT_TYPES = {
    'single-choice': 'radio',
    'multiple-choice': 'checkbox',
    text: 'text'
  }
  
  export {BADGE_TYPES_COLORS, BADGE_TYPES_NAMES, MODAL_QUESTION_TYPES}