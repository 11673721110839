import React from 'react'
import {Wrapper, Dashboard, Resume, Placement} from './styles'
import {Badge} from 'shared/components/Badge'
import {Subtitle, RegularText} from 'shared/components/index'
import {sortOrgsByRanking} from './utils/sort-orgs-by-rank'
import {usePaginator} from 'hooks/use-paginator'

const Ranking = ({organizations}) => {
  const orderedOrgs = sortOrgsByRanking(organizations);
  const {items, pages, pagesList, setPageNumber} = usePaginator(orderedOrgs, 5)

  return (
    <Wrapper>
      <Subtitle>
        Ranking
      </Subtitle>
      {
        orderedOrgs.length === 0
         ? <Dashboard>
            <div style={{padding: '1rem'}}>
              <p>Aún ninguna organización tiene formularios completados.</p>
              <p>Cuando los completen, podrás visualizar el ranking.</p>
            </div>
          </Dashboard>
         : items
            .map((r, idx) => (
            <Dashboard key={r.name}>
              <Resume>
                <div>
                  <Placement>{idx + 1}</Placement>
                  <RegularText>{r.name}</RegularText>
                </div>
                <Badge 
                  background='rgba(108, 63, 152, 0.2)'
                  color='#6C3F98'
                >
                  {r.score} pts
                </Badge>
              </Resume>
            </Dashboard>
          ))
      }


    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
      {pagesList.map(lof => (
        <Badge
          key={lof}
          background='rgba(108, 63, 152, 0.2)'
          color='#6C3F98'
          fontWeight='bold'
          onClick={() => setPageNumber(lof)}
          style={{cursor: 'pointer', textAlign: 'center'}}
        >
          {lof}
        </Badge>
      ))}
    </div> 
    </Wrapper>
  )
}

export {Ranking}