import React, {useState} from 'react'
import styled from 'styled-components'
import {useAuth} from 'auth/context'
import {Button} from '../Button'

const ErrorFallback = ({error}) => { 
  const {logout} = useAuth()

  const [timer, setTimer] = useState(3)

  if(error.response?.status === 401) {
    setTimeout(() => {
      window.localStorage.removeItem('__auth_provider_token__')
      window.location.href = '/login'
    }, 3000)

    setTimeout(() => {
      setTimer(timer - 1)
    }, 1000)

    return (
      <StyledError>
        <div style={{display: 'flex', alignItems: 'center'}}>
          Expiró tu sesion, serás redirigido en <span style={{fontSize: '20px', fontWeight: 600, margin: '0 1rem'}}>{timer}</span> segundos  para logearte nuevamente.
        </div>
     </StyledError>
    )
  }
  return (
    <StyledError>
      <div >
        <p style={{display: 'flex', alignItems: 'center'}}>Oops, algo salio mal. Por favor 
          <Button margin='0 0.5rem' padding='0.5rem' onClick={() => window.location.href = window.location.href}>
            haz click aqui
          </Button> e intenta nuevamente.
        </p>
        <p style={{display: 'flex', alignItems: 'center'}}>
          Si el error persiste, 
          <Button margin='0 0.5rem' padding='0.5rem' onClick={() => window.location.href = '/cuestionary'}>
          haz click aqui
          </Button> 
          para ser redirigido al inicio.
        </p>
      </div>
    </StyledError>
  )
}

const StyledError = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${props => props.theme.colors.white};
  color: black;
`


export {ErrorFallback}