import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Modal, Button, Badge} from 'shared/components/index'
import {Options} from './components/Options'
import {QTitle} from './components/QTitle'
import {Type} from './components/Type'
import {Module} from './components/Module'
import {Trash, Radio, Checkbox, Text, Recommendation} from 'shared/assets/svgs/index'
import {RegularText, SmallText, Title} from 'shared/components/index'

const MODAL_TYPES = {
  add: 'add',
  edit: 'edit'
}

const OPTION_TYPES = {
  'single-choice': <Radio />,
  'multiple-choice': <Checkbox />,
  text: <Text />
}

const Question = ({
  openModalType, 
  setOpenModalType, 
  change,
  questionAction,
  fields,
  error,
  actionText,
  errors,
  setQuestionFound,
  actionType,
  setEditQuestionFields
  }) => {

  const [recommendationDescription, setRecommendationDescription] = useState('')
  const [threshold, setThreshold] = useState()

  const deleteOption = (description) => {
    const optionsCopy = [...fields?.options]
    const filterOpt = optionsCopy.filter(oc => oc.description !== description)

    change(actionType, {options: [...filterOpt]})
    setEditQuestionFields(oldState => ({...oldState, options: [...filterOpt]}))
  }

  const deleteRecommendation = (recommendation) => {
    const recommendationsCopy = [...fields?.recommendations]
    const filterRecommendations = recommendationsCopy.filter(rc => rc.description !== recommendation)
    
    change(actionType, {recommendations: [...filterRecommendations]})
    setEditQuestionFields(oldState => ({...oldState, recommendations: [...filterRecommendations]}))
  }

  const addRecommendation = () => {

    if(!recommendationDescription) return

    change(
      actionType,
      {recommendations: [...fields?.recommendations, 
      {description: recommendationDescription}]
      }
    )
    
    setEditQuestionFields(oldState => ({...oldState, recommendations: [...fields?.recommendations, {description: recommendationDescription}]}))
    setRecommendationDescription('')
    setThreshold(0)
  }

  const handleCancelAction = () => {
    setQuestionFound(null)
    setOpenModalType('') 
  }


  const handleMutateThreshold = (value) => {
    change(actionType, {recommendationThreshold: value})
    setEditQuestionFields(prevState => ({...prevState, recommendationThreshold: value}))
  }

  return (
    <>
      <Modal
        isOpen={openModalType === MODAL_TYPES[openModalType]}
        toggleModal={handleCancelAction}
        width='70rem'
        height='45rem'
        justifyCont='flex-start'
        alignItems='flex-start'
      >
        <form 
          style={{width: '60%', height: '100%', margin: '2rem'}}
          onSubmit={questionAction}
        >
          <QTitle 
            titleText={actionText}
            inputValue={fields?.title}
            change={change}
            margin='0'
            actionType={actionType}
            setEditQuestionFields={setEditQuestionFields}
          />
          <div style={{display: 'flex', alignItems: 'center'}}> 
            <Type 
              change={change}
              fieldsType={fields?.type}
              actionType={actionType}
              setEditQuestionFields={setEditQuestionFields}
            />
          </div>
          {fields?.type !== 'text' 
            ? <Options 
                change={change}
                state={fields}
                actionType={actionType}
                setEditQuestionFields={setEditQuestionFields}
              />
            : null
          }
          
          <Module 
            moduleValue={fields?.module}
            change={change}
            actionType={actionType}
            setEditQuestionFields={setEditQuestionFields}
          />
          <div style={{display: 'flex', alignItems: 'center', margin: '1rem 0'}}>
            <SmallText margin='0 0.5rem 0 0'>Puntaje máximo para las recomendaciones:</SmallText>
            <input 
              type='number' 
              name='threshold'
              step='any'
              onChange={(e) => handleMutateThreshold(parseFloat(e.target.value))}
              required
              defaultValue={!fields?.recommendationThreshold ? 0 : fields?.recommendationThreshold}
              min={0}
              max={2}
              style={{margin: '0 1rem 0 0', padding: '0.5rem 0.25rem', width: '10%', border: '1px solid rgba(46, 45, 90, 0.68)', borderRadius: '8px'}}
            />
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <SmallText margin='0 0.5rem 0 0'>Recomendaciones:</SmallText>
          </div>
          <div>
            <textarea 
              id="recommendation" 
              name="recommendation" 
              rows="4" 
              cols="75"
              value={recommendationDescription}
              onChange={(e) => setRecommendationDescription(e.target.value)}
              style={{margin: '1rem 0'}}
            />
            <Badge
              background='rgba(108, 63, 152, 0.2)'
              color='#6C3F98'
              margin='0 0.5rem'
              fontWeight='bold'
              onClick={() => addRecommendation()}
              style={{width: 'fit-content', cursor: 'pointer', textAlign: 'center'}}
              >
                + Agregar recomendación
            </Badge>
          </div>
          {error ? <ErrorMessage>Recordá que los campos título, tipo, y módulo son obligatorios</ErrorMessage> : null}
          <div style={{display: 'flex', justifyContent: 'flex-start', margin: '2rem 0'}}>
            <Button 
              background='#6C3F98' color='#FFFFFF' fontSize='base' padding='0.75rem 1.5rem'
              type='submit'
            >
              + {actionText}
            </Button>
            <Button background='#F9F8FA' color='#3D4852' fontSize='base' padding='0.75rem 1.5rem' style={{margin: '0 1rem'}}
              onClick={() => handleCancelAction()}
            >
              Cancelar
            </Button>
          </div>
        </form>
        <Preview style={{margin: '2rem'}}>
          <Title 
            fontSize='base'
            fontWeight='medium'
          >
            Vista previa
          </Title>
          <Title 
            fontSize='sm'
            fontWeight='semiBold'
          >
            Pregunta
          </Title>
          <RegularText 
            fontSize='sm'
            fontWeight='semiBold'
          >
           {fields?.title}
          </RegularText>
          <Title 
            fontSize='sm'
            fontWeight='semiBold'
          >
            Opciones
          </Title>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <SmallText margin='0 1rem'>Orden</SmallText>
            <SmallText margin='0 1rem'>Descripción</SmallText>
            <SmallText margin='0 1rem'>Score</SmallText>
            <SmallText margin='0 1rem'></SmallText>
          </div> 
          {fields?.options?.map((opt, idx) => 
            <div key={opt.description} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <RegularText style={{width: '20%', display: 'flex', justifyContent: 'center'}}>
                {idx + 1}
              </RegularText>
              <RegularText margin='0 1rem' style={{width: '50%', display: 'flex', justifyContent: 'center'}}>{opt.description}</RegularText>
              <RegularText margin='0 1rem' style={{width: '20%', display: 'flex', justifyContent: 'center'}}>{opt.score}</RegularText>
              <Button
                background='#F9F8FA'
                borderRadius='100px'
                padding='0.75rem'
                onClick={() => deleteOption(opt.description)}
              >
                <Trash />
              </Button>
            </div>
          )}
          <Title 
            fontSize='sm'
            fontWeight='semiBold'
          >
            Recomendaciones
          </Title> 
          {fields?.recommendations?.map(recommendation => (
            <div key={recommendation.description} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <span>
                <Recommendation />
              </span>
              <RegularText margin='0 1rem'>{recommendation.description}</RegularText>
              <Button
                background='#F9F8FA'
                borderRadius='100px'
                padding='0 0.75rem'
                onClick={() => deleteRecommendation(recommendation.description)}
              >
                <Trash />
              </Button>
            </div>
          ))}
          {errors === 'text-with-options' 
            ? <ErrorMessage>Recordá que si la pregunta es de tipo texto, no puede tener opciones de pregunta. Por favor borralas.</ErrorMessage> 
            : errors === 'less-than-two-options' 
              ? <ErrorMessage>Recordá que la pregunta debe tener un mínimo de dos opciones</ErrorMessage> 
              : null
          } 
        </Preview>
      </Modal>
    </>
  )
}

const ErrorMessage = styled.p`
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.error};
  margin-top: 0.5rem;
`

const Preview = styled.div`
  width: 40%;
  max-height: 70vh;
  overflow-y: auto;

  background: rgb(244, 246, 250);
  border-radius: 10px 0px 0px 10px;

  &::-webkit-scrollbar-thumb {
    background: rgba(108,63,152,0.2);
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 10px;
    border: none;
    border-radius: 8px;
  }

  padding: 1rem;
  > * {
    margin: 1rem 0;
  }
`

export {Question}