import React from 'react'
import {Select} from 'shared/components/Select'
import {MODULES_TYPES} from '../../types'

const Module = ({moduleValue, change, actionType, setEditQuestionFields}) => {
  const defaultModule = moduleValue ? moduleValue : MODULES_TYPES['compromiso']

  const handleInput = (value) => {
    change(actionType, {module: value})
    setEditQuestionFields(prevState => ({...prevState, module: value}))
  }

  return (
    <div>
      <Select 
        name='module'
        defaultValue={defaultModule}
        onChange={(e) => handleInput(e.target.value)}
        border='1px solid rgba(46, 45, 90, 0.68);'
        width='40%'
        margin='2rem 0'
        padding='0.75rem'
      >
        <option 
          value={MODULES_TYPES['compromiso']}
      >
          Módulo: {MODULES_TYPES['compromiso']}
        </option>
        <option 
          value={MODULES_TYPES['implementacion']}
        >
          Módulo: {MODULES_TYPES['implementacion']}
        </option>
        <option 
          value={MODULES_TYPES['medicion']}
        >
          Módulo: {MODULES_TYPES['medicion']}
        </option>   
      </Select>
    </div>
  )
}

export {Module}