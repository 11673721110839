import React, {useState} from 'react'
import styled from 'styled-components'
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import {ModalProvider} from 'styled-react-modal'
import {useOrganizations} from './hooks/organizations'
import {useFormularies} from 'hooks/formularies'
import {Sidebar, Header} from './shared/components/index'
import {Desktop, Organizations, EditOrganization, OrganizationDetail, Rating, Formularies, Invitations} from './pages/index'
import {useAuth} from './auth/context'
import { Spinner } from 'shared/assets/svgs'

const AuthenticatedRoutes = ({forms}) => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/formularios" />}/>
      <Route exact path='/escritorio' element={<Desktop  />}></Route>
      <Route exact path='/organizaciones' element={<Organizations  />}></Route>
      <Route exact path='organizaciones/edit/:id' element={<EditOrganization />}></Route>
      <Route exact path='organizaciones/resume/:id' element={<OrganizationDetail />}></Route>
      <Route exact path='/invitaciones' element={<Invitations  />}></Route>
      <Route exact path='/indice' element={<Rating forms={forms}  />}></Route>
      <Route exact path='/formularios' element={<Formularies formularies={forms} />}></Route>
    </Routes>
  )
}

const AuthenticatedApp = () => {
  // const {data: organizations, orgsStatus} = useOrganizations()
  const {user, logout} = useAuth()
  const [isFalse, setIsFalse] = useState(false)
  const {data: forms, status: formsStatus} = useFormularies()

  return (
    <Router>
      <ModalProvider>
        {formsStatus === 'loading'
          ? <Spinner />
          : <Wrapper>
            <Sidebar user={user} logout={logout} />
            <div 
              style={{background: '#F4F6FA', width: '100%', minHeight: '100vh'}}
            >
              <Header />
              <div style={{margin: '4rem'}}>
                <AuthenticatedRoutes 
                  //  
                  forms={forms}
                />
              </div>
            </div>
          </Wrapper>
        }
      </ModalProvider>          
    </Router>
  );
}

const Wrapper = styled.div`
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
`

export {AuthenticatedApp}
