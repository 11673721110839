import React, {useState} from 'react';
import {Button} from 'shared/components/Button';
import {Input, Title} from 'shared/components/index'
import {useAsync} from 'hooks/use-async'
import {Nodos, User, Lock, Eye} from 'shared/assets/svgs/LoginIcons/index'
import {Spinner} from 'shared/assets/svgs/index'
import {Wrapper, Form, InputWrapper, StyledHeader, SectionWrapper, ErrorMessage} from './styles'
import insight_logo from 'shared/images/insight_logo.png'


const errors = {
  'Unauthorized': 'Usuario o contraseña incorrectos'
}

const Login = ({ login }) => {
  const [showPassword, setShowPassword] = useState(false)
  const passwordtype = showPassword ? 'text' : 'password'
  const {isLoading, run, isError} = useAsync()

  const handleSubmit = (e) => {
    e.preventDefault()
    const { email, password } = e.target.elements
    run(login({username: email.value, password: password.value}))
  }
  
  return (
    <Wrapper>
      <StyledHeader>
        <SectionWrapper>
          <img src={insight_logo} width='350' height='auto' />
          <Form 
            onSubmit={handleSubmit} 
          >
            <Title>Iniciar sesión</Title>
            <InputWrapper>
              <div style={{position: 'absolute', bottom: '12px', left: '12px'}}>
                <User /> 
              </div>
              <Input  
                placeholder='Email'
                type='text'
                name='email' 
                padding='1rem 2.5rem'
                width='100%'
              />
            </InputWrapper>
            <InputWrapper>
              <div style={{position: 'absolute', bottom: '12px', left: '12px'}}>
                <Lock /> 
              </div>
              <Input
                placeholder='Password'
                type={passwordtype}
                name='password'
                padding='1rem 2.5rem'
                width='100%'
              />
              <Button 
                background='transparent'
                type='button'
                style={{position: 'absolute', bottom: '30%', right: '12px'}}
                onClick={() => setShowPassword(prevState => !prevState) }
              >
                <Eye /> 
              </Button>
            </InputWrapper>   
            <Button 
              type='submit' 
              padding='0.75rem'
              background='#00B9B4'
              fontSize='base'
              fontWeight='bold'
              style={{width: '100%'}}
            >
              {isLoading ? <Spinner fill='white' /> : 'Ingresar'}
            </Button>
            {isError ? <ErrorMessage>Revisa tus credenciales. Email o contraseña incorrectos.</ErrorMessage>: null}
          </Form>
        </SectionWrapper>
      </StyledHeader>
    </Wrapper>
  )
}

export {Login}