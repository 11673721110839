import { createGlobalStyle } from 'styled-components'


const GlobalStyle = createGlobalStyle`
  body { 
    margin: 0;
    font-family: 'Inter', sans-serif;
  }
` 

export default GlobalStyle