const formsCompletedChartData = (organizations = []) => {
    const orgsCopy = [...organizations]

    const formsCompletedSum = orgsCopy.map(oc => oc.nCompleted).reduce((acc, el) => acc + el, 0)
    const formsInCourseSum = orgsCopy.map(oc => oc.nInProgress).reduce((acc, el) => acc + el, 0)

    return [
      {name: 'Completado', value: formsCompletedSum},
      {name: 'En curso', value: formsInCourseSum}
    ]
}

export {formsCompletedChartData}