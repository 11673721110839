import React from 'react'

const PhoneIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.22471 9.75118C7.87302 11.0766 8.94739 12.1461 10.2758 12.7884C10.3729 12.8344 10.4804 12.8544 10.5877 12.8463C10.6949 12.8381 10.7982 12.8022 10.8874 12.7421L12.8433 11.4378C12.9298 11.3802 13.0293 11.345 13.1328 11.3355C13.2364 11.3259 13.3407 11.3424 13.4362 11.3834L17.0954 12.9516C17.2197 13.0044 17.3234 13.0961 17.3911 13.213C17.4587 13.33 17.4865 13.4657 17.4702 13.5998C17.3546 14.5048 16.913 15.3366 16.2282 15.9395C15.5434 16.5423 14.6624 16.8749 13.75 16.875C10.9321 16.875 8.22956 15.7556 6.23699 13.763C4.24442 11.7704 3.125 9.06791 3.125 6.24999C3.12505 5.33762 3.45765 4.45656 4.06053 3.77176C4.66341 3.08697 5.49523 2.64541 6.40023 2.52975C6.53431 2.51353 6.67002 2.54131 6.78693 2.60892C6.90384 2.67654 6.99561 2.78031 7.04841 2.90461L8.61798 6.56695C8.65858 6.66168 8.67512 6.76497 8.66614 6.86764C8.65715 6.97031 8.62291 7.06917 8.56647 7.1554L7.26676 9.14132C7.2076 9.23066 7.17263 9.33381 7.16526 9.4407C7.15789 9.54759 7.17837 9.65457 7.22471 9.75118V9.75118Z" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    )
}

export {PhoneIcon}
