import React from 'react'

const CaretDown = () => {
    return (
        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.75 1.5L5 5.25L1.25 1.5" stroke="#2E2D5A" stroke-opacity="0.68" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    )
}

export {CaretDown}
