import React from 'react'

const CheckIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27 9.00061L13 23L6 16.0006" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
    )
}

export {CheckIcon}
