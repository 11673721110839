import styled from 'styled-components'
import {Link} from 'react-router-dom'

const Wrapper = styled.main`
  background: ${props => props.theme.colors.white};
  border: 1px solid transparent;
  border-radius: 20px;

  /* margin: 4rem; */

  > div {
    margin: 2rem;
  }
`

const Bar = styled.div`
  display: flex;
  justify-content: space-between;
`

const LiWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`

const Button = styled.button`
  background: ${props => props.background};
  border: none;
  border-radius: 9999px;
  padding: 0.55rem 1.25rem;
  cursor: pointer;

  margin-right: 0.25rem;

  &:disabled {
    opacity: 0.25;
  }
`

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 42px;
  height: 42px;

  background: ${props => props.theme.colors.lightGray};

  border: transparent;
  border-radius: 50%;

  margin: 0 0.5rem;

  cursor: pointer;
`

const HeadersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  margin: 1rem 0;

  > * {
    font-size: 14px;
    font-weight: 600;
    color: rgba(46, 45, 90, 0.68);
    cursor: pointer;
  }
`

export {Wrapper, Bar, LiWrapper, StyledLink, HeadersWrapper, Button}