import React from 'react'
import {PieChart, Pie, Cell} from 'recharts'

const COLORS = ['#00B9B4', '#E6148B'];

const DonutChart = ({chartData}) => {
  return (
    <PieChart 
      width={300} 
      height={200}
      style={{position: 'absolute', left: '50%', transform: "translate(-50%, -50%)"}}
    >
    <Pie
      data={chartData}
      cx={160}
      cy={90}
      innerRadius={60}
      outerRadius={80}
      fill="#8884d8"
      dataKey="value"
    >
      {chartData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
      ))}
    </Pie>
  </PieChart>
  )
}

export {DonutChart}
   