const sortAndFilter = (orgs = [], searchVal = '') => {
    const orgsWithRanking = [...orgs]
      .filter(org => org.answerStatus === 'completed')
      .sort((a, b) => b.score - a.score)
      .map((org, idx) => {
        return {
          ...org,
          ranking: idx + 1
        }
      }
    )
    return function sortAndFilterFn (bool = true) {
      return bool
        ? [...orgsWithRanking].filter(el => el.name?.toLowerCase().includes(searchVal.toLowerCase()))
        : [...orgsWithRanking].sort((a, b) => a.score - b.score).filter(el => el.name?.toLowerCase().includes(searchVal.toLowerCase()))
    }
  }
  
  export {sortAndFilter}