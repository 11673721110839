import React from 'react'

const RecommendationIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.99927 13H9.99927V26H3.99927C3.73405 26 3.4797 25.8946 3.29216 25.7071C3.10462 25.5196 2.99927 25.2652 2.99927 25V14C2.99927 13.7348 3.10462 13.4804 3.29216 13.2929C3.4797 13.1054 3.73405 13 3.99927 13V13Z" stroke="#6C3F98" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.99927 13L14.9993 3C15.5246 3 16.0447 3.10346 16.53 3.30448C17.0153 3.5055 17.4563 3.80014 17.8277 4.17157C18.1991 4.54301 18.4938 4.98396 18.6948 5.46927C18.8958 5.95457 18.9993 6.47471 18.9993 7V10H26.7337C27.0173 10 27.2976 10.0603 27.5561 10.1769C27.8146 10.2935 28.0454 10.4638 28.2331 10.6764C28.4207 10.889 28.5611 11.1391 28.6447 11.41C28.7284 11.681 28.7534 11.9667 28.7183 12.2481L27.2183 24.2481C27.1578 24.7318 26.9228 25.1767 26.5573 25.4994C26.1919 25.822 25.7212 26 25.2337 26H9.99927" stroke="#6C3F98" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export {RecommendationIcon}
