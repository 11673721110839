import React from 'react'

const Close = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35Z" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeMiterlimit="10"/>
<path d="M25 15L15 25" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M25 25L15 15" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    )
}

export {Close}