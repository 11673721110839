import React, {useRef} from 'react'
import styled from 'styled-components'
import {Link, useLocation} from 'react-router-dom'
import {ListItem} from '../ListItem'
import {Button} from '../Button'
import {Nodos, Desktop, Organizations, Invitations, Chart, Chat, Power} from '../../assets/svgs/SidebarIcons/index'

const listItems = [
  { text: 'Escritorio', icon: <Desktop />},
  { text: 'Organizaciones', icon: <Organizations />},
  { text: 'Invitaciones', icon: <Invitations />},
  { text: 'Indice', icon: <Chart />},
  { text: 'Formularios', icon: <Chat />}
]

const pathToListItems = listItems.map(li => {
  return {
    ...li,
    path: `/${li.text}`.toLowerCase()
  }
})

const Sidebar = ({ logout }) => {
  const { pathname } = useLocation()

  return (
    <Wrapper>
      <div style={{marginBottom: '3rem'}}>
        <Nodos />
      </div>
      {pathToListItems.map(li => (
        <Link 
          to={li.path}
          style={{textDecoration: 'none'}}
          key={li.path}
        >
          <ListItem
            key={li.text}
            margin='3rem 0'
            fontSize='base'
            fontWeight='600'
            color={pathname === li.path ? '#6C3F98' : '#3D4852'}
          >
            <span style={{margin: '0 0.5rem'}}>
            {React.cloneElement(li.icon, {color: pathname === li.path ? '#6C3F98' : '#3D4852'})}
            </span>
            {/* </span> */}
            {li.text}
          </ListItem>
        </Link>
      ))}
      <Button
        margin='2rem 0'
        background='transparent'
        color='#3D4852'
        onClick={() => logout()}
      >
        <Power /> <span style={{margin: '0 0.5rem'}}>Cerrar sesion</span>
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 2rem;
`

export {Sidebar}