import React from 'react'

const OrganizationsIcon = ({color = '#3D4852'}) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 11.8125C16.4162 11.8125 18.375 9.85375 18.375 7.4375C18.375 5.02125 16.4162 3.0625 14 3.0625C11.5838 3.0625 9.625 5.02125 9.625 7.4375C9.625 9.85375 11.5838 11.8125 14 11.8125Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M20.5625 23.1875C22.9787 23.1875 24.9375 21.2287 24.9375 18.8125C24.9375 16.3963 22.9787 14.4375 20.5625 14.4375C18.1463 14.4375 16.1875 16.3963 16.1875 18.8125C16.1875 21.2287 18.1463 23.1875 20.5625 23.1875Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.4375 23.1875C9.85375 23.1875 11.8125 21.2287 11.8125 18.8125C11.8125 16.3963 9.85375 14.4375 7.4375 14.4375C5.02125 14.4375 3.0625 16.3963 3.0625 18.8125C3.0625 21.2287 5.02125 23.1875 7.4375 23.1875Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    )
}

export {OrganizationsIcon}