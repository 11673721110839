import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  background: ${props => props.theme.colors.violet};
`

const Form = styled.form`
  width: 25vw;
  max-width: auto;

  background: ${props => props.theme.colors.white};
  box-shadow: 1px 1px 30px 1px rgba(15, 17, 38, 0.05);
  border-radius: 10px;
  padding: 4rem 2rem;

  margin: 2rem 0;

  > * {
    margin-bottom: 2rem;
  }
`

const InputWrapper = styled.div`
  position: relative;
`

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;

  margin: 3rem 4rem;
`

const SectionWrapper = styled.div`
  width: 100%;
  text-align: center;
`

const ErrorMessage = styled.p`
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.error};
  margin-top: 0.5rem;
`

export {Wrapper, InputWrapper, StyledHeader, SectionWrapper, Form, ErrorMessage}