import React from 'react'
import {FormWrapper, Wrapper, Dashboard, Score, Text} from './styles'
import {Subtitle} from 'shared/components/index'

const Formularies = ({formsCompleted, formsInCourse}) => {
  return (
    <FormWrapper>
      <Subtitle>
        Formularios
      </Subtitle>
      <Wrapper>
        <Dashboard>
          <Score color='#00B9B4'>
            {formsCompleted}
          </Score><br />
          <Text>
            Completados
          </Text>
        </Dashboard>
        <Dashboard>
          <Score color='red'>
            {formsInCourse}
          </Score><br />
          <Text>
            En curso
          </Text>
        </Dashboard>
      </Wrapper>
    </FormWrapper>
  )
}

export {Formularies}