const buildPaginator = (arr = [], pageNumber = 1, size = 10) => {
  const arrayCopy = [...arr]
  const pages = Math.ceil(arrayCopy.length / size)

  return {
    items: arrayCopy.slice((pageNumber - 1) * size, pageNumber * size),
    pages,
    pagesList: [...Array(pages)].map((_, i) => i + 1)
  }
}
                                  

export {buildPaginator}