import React from 'react'
import styled from 'styled-components'

const Subtitle = ({children, margin}) => {
  return (
    <StyledSubtitle margin={margin}>
      {children}
    </StyledSubtitle>
  )
}

const StyledSubtitle = styled.h1`
  font-size: ${props => props.theme.fontSizes.customBase};
  font-weight: ${props => props.theme.fontWeight.semiBold};
  color: ${props => props.theme.colors.gray};

  margin: ${props => props.margin};
`

export {Subtitle}