import React from 'react';
import styled from 'styled-components';

const Button = ({children, background = '#6C3F98', fontSize = 'customBase', margin, type, fontWeight, style, color = 'white', padding, borderRadius = '12px', disabled, onClick}) => {
  return (
    <StyledButton
      onClick={onClick}
      background={background}
      fontSize={fontSize}
      style={style}
      color={color}
      fontWeight={fontWeight}
      type={type}
      borderRadius={borderRadius}
      padding={padding}
      margin={margin}
      disabled={disabled}
    >
        {children}
    </StyledButton>
  )
}

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: ${props => props.margin};
  padding: ${props => props.padding};

  color: ${props => props.color};
  background: ${props => props.background};

  font-size: ${props => props.theme.fontSizes[props.fontSize]};
  font-weight: ${props => props.fontWeight};
  text-align: center;

  border: none;
  border-radius: ${props => props.borderRadius};

  cursor: pointer;

  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
`

export {Button}