import axios from 'axios'

const apiURL = process.env.REACT_APP_API_URL
const localStorageKey = '__auth_provider_token__'

async function client(
  endpoint,
  {data, headers: customHeaders, ...customConfig} = {},
) {

  const token = localStorage.getItem('__auth_provider_token__')

  const config = {
    method: data ? 'POST' : 'GET',
    data: data ? data : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      'Content-Type': data ? 'application/json' : undefined,
      ...customHeaders,
    },
    ...customConfig,
  }

  const req = axios(`${apiURL}/${endpoint}`, config).then(async response => {
    const data = response.data
    if (response.status === 200) {
      return data
     } else {
       return Promise.reject(data)
     }
  })

  return req
}

export {client}
