import styled from 'styled-components'

const PageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Wrapper = styled.section`
  width: 45%;
`

const Dashboard = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  background: ${props => props.theme.colors.white};

  margin: 1rem 0;
  padding: 1rem 0;

  box-shadow: 1px 1px 30px 1px rgba(15, 17, 38, 0.05);
  border-radius: 12px;
`

const Ranking = styled.span`
  color: ${props => props.theme.colors.gray};
  font-size: ${props => props.theme.fontSizes.base};
  font-weight: ${props => props.theme.fontWeight.semiBold};
  line-height: 19px;
`

const Score = styled.span`
  font-size: ${props => props.fontSize ? props.fontSize : props.theme.fontSizes.medium};
  font-weight: ${props => props.theme.fontWeight.semiBold};
  color: ${props => props.color};
`

const Dot = styled.span`
  height: 10px;
  width: 10px;
  background-color: ${props => props.bg};
  border-radius: 50%;
  display: inline-block;

  margin: 0 1rem;
`

export {PageWrapper, Wrapper, Dashboard, Ranking, Score, Dot}