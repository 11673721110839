
import styled from 'styled-components'

const Bar = styled.div`
  position: relative;
  width: 25vw;
  height: 1vh;

  background: ${props => props.bg};
  border-radius: 12px;

  margin: 1rem 0;
`

const Completed = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;

  width: ${props => props.width}%;
  height: 1vh;

  background: ${props => props.completedBg};
  border-radius: 12px;
`

export {Bar, Completed}