import React from 'react'

const DoubleChecksIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.5625 6.5625L4.6875 13.4375L1.25 10.0002" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.7498 6.5625L11.8748 13.4375L10.0488 11.6116" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
    )
}

export {DoubleChecksIcon}
