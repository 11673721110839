import styled from 'styled-components'

const Wrapper = styled.div``

const Dashboard = styled.div`
  background: ${props => props.theme.colors.white};

  box-shadow: 1px 1px 30px 1px rgba(15, 17, 38, 0.05);
  border-radius: 12px;

  padding: 1rem;

  width: 30vw;
`

const Resume = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 1rem 0;
  > div {
    display: flex;
    align-items: center;
  }
`

export {Wrapper, Dashboard, Resume}

