import React from 'react'
import styled from 'styled-components'

const Select = ({name, defaultValue, value, onChange, children, bg ='#FFFFFF', padding, border, width, margin}) => {
  return (
		<>
			<StyledSelect 
				name={name} 
				padding={padding}
				defaultValue={defaultValue}
				onChange={onChange}
				bg={bg}
				border={border}
				width={width}
				margin={margin}
				value={value}
			>
				{children}
			</StyledSelect>
		</>
	)
}

const StyledSelect = styled.select`
	display: block;

	width: ${props => props.width || '100%'};
	box-sizing: content-box;

	font-size: ${props => props.theme.fontSizes.base};
	line-height: 18px;
	color: ${props => props.theme.colors.dark};

  border: ${props => props.border || '1px solid rgba(46, 45, 90, 0.68)'};
  border-radius: 10px;

  padding: ${props => props.padding || '0.5rem'}; 

  background: ${props => props.bg};

	margin: ${props => props.margin || '1rem 0'};

`

export {Select}