import styled from 'styled-components'

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;

  margin: 3rem 4rem;

  > div {
      display: flex;
      align-items: flex-start;
  }
`

const Text = styled.span`
  display: flex;
  align-items: center;
  
  font-size: ${props => props.theme.fontSizes.base};
  font-weight: ${props => props.theme.fontWeight.semiBold};
`

const Title = styled.h1`
  font-size: ${props => props.theme.fontSizes.extended};
  font-weight: ${props => props.theme.fontWeight.semiBold};

  color: ${props => props.theme.colors.gray};
`

const ModalWrapper = styled.div`
  width: 100%;
  text-align: center;
  display: block;
`

export {StyledHeader, Text, Title, ModalWrapper}