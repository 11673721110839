import React from 'react'
import {Subtitle, Input} from 'shared/components/index'

const QTitle = ({titleText, inputValue = '', change, margin, actionType, setEditQuestionFields}) => {
  const handleInput = (value) => {
    change(actionType, {title: value})
    setEditQuestionFields(prevState => ({...prevState, title: value}))
  }
  return (
    <>
      <Subtitle margin={margin}>{titleText}</Subtitle>
      <Input
        placeholder='Título de la pregunta'
        padding='0.75rem'
        onChange={(e) => handleInput(e.target.value)}
        type='text'
        name='title'
        id='title'
        defaultValue={inputValue}
        required
      />
    </>
  )
}

export {QTitle}