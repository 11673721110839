import React, {useEffect, useState} from 'react'
import {Title, Search, Subtitle, SmallText, Badge, Button, Select} from 'shared/components/index'
import {ArrowRight} from 'shared/assets/svgs/RatingPageIcons/index'
import {Spinner, Toggler} from 'shared/assets/svgs/index'
import {PageWrapper, Wrapper, Dashboard, Ranking, Score, Dot} from './styles'
import {sortAndFilterOrgs} from 'helpers/sort-and-filter-orgs'
import {ProgressBar, TinyBarChart, DonutChart} from './components/index'
import {formsCompletedChartData, getFormsBySize} from './helpers/index'
import {OrganizationDetail} from './Detail'
import {buildPaginator} from 'helpers/paginator'
import {useFormularies, useIndexFormularyById} from 'hooks/formularies'
import {getFormsByCharacteristic} from './helpers/forms-by-characteristic'
import { sortAndFilter } from './helpers/sort-and-filter'

const SIZES_TYPES = {
  small: 'Pequeña',
  medium: 'Mediana',
  large: 'Grande'
}

const Rating = ({forms}) => {

  const [isOpenOrgDetailsModal, setIsOpenOrgDetailsModal] = useState(false)
  const [ranking, setRanking] = useState()
  const [org, setOrg] = useState({})
  const [searchVal, setSearchVal] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const [isHighestToLowest, setIsHighestToLowest] = useState(true)
  const [formSelectedId, setFormSelectedId] = useState(forms[0]?.id)

  const indexData = useIndexFormularyById(formSelectedId)

  const totalFormsByCountry = getFormsByCharacteristic(indexData?.data?.index?.organizationLocations)
  const totalFormsByIndustry = getFormsByCharacteristic(indexData?.data?.index?.organizationSectors) 

  const sortedAndFilteredOrgs = sortAndFilter(indexData?.data?.organizations, searchVal)

  const donutChartData = formsCompletedChartData(indexData?.data?.organizations)
  const actionOnOrgDetails = (bool, org, ranking) => {
    setIsOpenOrgDetailsModal(bool)
    setOrg(org)
    setRanking(ranking)
  }

  const handleFormSelectedId = ({target}) =>{
    setFormSelectedId(target.value);
  }

  const paginator = buildPaginator(sortedAndFilteredOrgs(isHighestToLowest), pageNumber, 10)


  return (
    <>
    {indexData.isLoading 
    ? <Spinner />
    :     <PageWrapper>
    <Wrapper>
      <Title margin='1rem 0'>Índice</Title>
      <div style={{display:'flex', justifyContent:'center', alignItems:'center', width: 'fit-content'}}>
        <label 
          htmlFor='years' 
          style={{fontSize:'18px', fontWeight: 400, margin: '0 1rem 0 0'}}
        >
          Año:
        </label>
        <Select 
          name='years' 
          id='years' 
          defaultValue={formSelectedId} 
          onChange={handleFormSelectedId}
          border='none'
          style={{backgroundColor: 'transparent', color: 'white', fontSize: '18px', border: 'none'}}
        >
          {forms?.map(form => (
            <option 
              key={form.id} 
              value={form.id}
            >
              {form?.publishedDate?.slice(0, 10)}
            </option>
          ))}
        </Select>
      </div>
      <div style={{display: 'flex'}}>
        <Search
          type='text'
          placeholder='Nombre organización...'
          width='60%'
          bg='#FFFFF'
          padding='1rem'
          id='search'
          name='search'
          onChange={(e) => setSearchVal(e.target.value)}
        />
        <Button 
          background='transparent'
          color='#2E2D5AAD'
          style={{margin: '0 0.5rem'}}
          onClick={() => setIsHighestToLowest(prevState => !prevState)}
        >
          <Toggler />
        </Button>
      </div>

      {paginator?.items
        .map((org, idx) => (
        <Dashboard key={org.id}>
          <Ranking>
            #{org.ranking}
          </Ranking>
          <div style={{width: '45%'}}>
            <Subtitle margin='0'>
              {org.name}
            </Subtitle>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <SmallText margin='0 1rem 0 0'>
                {org?.answerCompletionDate?.slice(0, 10)}
              </SmallText>
              <SmallText margin='0 1rem 0 0'>
                {SIZES_TYPES[org.size]}
              </SmallText>
              <SmallText margin='0 1rem 0 0'>
                {org.sector}
              </SmallText>
            </div>
          </div>
          <Score color='#6C3F98'>
            {org.points_amount}
          </Score>
          <div style={{display: 'flex'}}>
            {/* <Button bg='transparent' mx='4'>
              <Mail />
            </Button> */}
            <Button 
              onClick={() => actionOnOrgDetails(true, org, org.ranking)}  
              background='transparent' 
              style={{margin: '0 0.25rem'}}
            >
              <ArrowRight />
            </Button>
          </div>
        </Dashboard>
      ))}
      
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
      {paginator?.pagesList?.map(lof => (
        <Badge
          key={lof}
          background='rgba(108, 63, 152, 0.2)'
          color='#6C3F98'
          fontWeight='bold'
          onClick={() => setPageNumber(lof)}
          style={{cursor: 'pointer', textAlign: 'center'}}
        >
          {lof}
        </Badge>
      ))}
    </div>

    </Wrapper>
  
    <Wrapper>
      <Dashboard style={{position: 'relative'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Dot bg='#00B9B4' />
          <div>
            <Score>
              {donutChartData[0].value}
            </Score>
            <SmallText>
              Completados
            </SmallText>
          </div>
        </div> 
        <div> 
          <SmallText weight='600'>
            Formularios
          </SmallText> 
          <DonutChart chartData={donutChartData} />
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Dot bg='#E6148B' />
          <div>
            <Score>
            {donutChartData[1].value}
            </Score>
            <SmallText>
              En curso
            </SmallText>
          </div>
        </div> 
      </Dashboard>
      <Dashboard style={{margin: '2rem 0'}}>
        <div>
          {indexData?.data?.index?.organizationLocations?.map(location => (
            <div key={location.name}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <span>{location.name}</span>
                <span>{parseInt(location.n)}</span>
              </div>
              <ProgressBar 
                bg='#E4D0F7'
                completedBg='#6C3F98'
                amount={parseInt(location.n)} 
                total={totalFormsByCountry}
              />
            </div>
            ))}
        </div>
      </Dashboard>
      <Dashboard>
        <div style={{textAlign: 'center'}}>
          <Score fontSize='40px'>
            {indexData?.data?.organizations?.length}
          </Score>
          <SmallText>
          Organizaciones con formularios activos
          </SmallText>
          <TinyBarChart chartData={indexData?.data?.index?.organizationSizes} />
        </div>
      </Dashboard>
      <Dashboard style={{marginTop: '2rem'}}>
        <div>
          {indexData?.data?.index?.organizationSectors?.map(sector => (
            <div key={sector.id}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <span>{sector.name}</span>
                <span>{sector.n}</span>
              </div>
              <ProgressBar
                bg='#D2FAF9'
                completedBg='#00B9B4' 
                amount={parseInt(sector.n)} 
                total={totalFormsByIndustry}
              />
            </div>
            ))}
        </div>
      </Dashboard>
    </Wrapper>

  {isOpenOrgDetailsModal 
    ? <OrganizationDetail 
        isOpenOrgDetailsModal={isOpenOrgDetailsModal} 
        setIsOpenOrgDetailsModal={setIsOpenOrgDetailsModal}
        org={org}
        ranking={ranking}
      /> 
    : null
  }
</PageWrapper>
    }
  

    </>
  )
}

export {Rating}