import React, {useState} from 'react'
import {StyledHeader, Text, Title, ModalWrapper} from './styles'
import {Modal} from '../../components/index'
import {Button} from '../Button'
import {PaperPlaneIcon} from '../../assets/svgs/Others/PaperPlane'

const Header = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  return (
    <StyledHeader>
      <Text>
        Eduardo Otero
      </Text>
      {/* <div>
        <Button 
          fontSize='sm' 
          onClick={() => setIsOpenModal(true)}
          style={{padding: '1rem 1.25rem'}}
          background='#6C3F98'
          borderRadius='12px'
        >
          + Invitar organización
        </Button>
      </div> */}
      <Modal
        isOpen={isOpenModal}
        toggleModal={setIsOpenModal}
        width='30rem'
        height='20rem'
      >
        <ModalWrapper>
          <Title>Invitar organización</Title>
          <div style={{display: 'flex', flexDirection: 'column', margin: '1rem 6rem'}}>
            <input style={{marginBottom: '1rem', padding: '0.75rem', border: '0.5px solid #2E2D5A', borderRadius: '10px'}} type='text' placeholder='Nombre organización' />
            <input style={{padding: '0.75rem', border: '0.5px solid #2E2D5A', borderRadius: '10px'}} type='email' placeholder='Correo electrónico' />
          </div>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Button 
              // style={{margin: '0 auto'}}
              fontSize='sm'  
              padding='0.75rem 1.25rem'
              style={{margin: '0 1rem'}}
              onClick={() => setIsOpenModal(false)}
            >
              <span style={{marginRight: '0.5rem'}}>
                <PaperPlaneIcon />
              </span>
              Enviar invitación
            </Button>
            <Button 
              background='#F9F8FA'
              color='#3D4852'
              fontSize='sm'  
              padding='1rem 1.5rem'
              style={{margin: '0 1rem'}}
              onClick={() => setIsOpenModal(false)}
            >
              Cancelar
            </Button>
          </div> 
        </ModalWrapper>
      </Modal>
    </StyledHeader>
  )
}

export {Header}