
import React from 'react'

const Lock = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.6246 6.1875H3.37459C3.06393 6.1875 2.81209 6.43934 2.81209 6.75V14.625C2.81209 14.9357 3.06393 15.1875 3.37459 15.1875H14.6246C14.9352 15.1875 15.1871 14.9357 15.1871 14.625V6.75C15.1871 6.43934 14.9352 6.1875 14.6246 6.1875Z" stroke="#2E2D5A" strokeOpacity="0.68" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.46834 6.1875V3.65625C6.46834 2.98492 6.73502 2.34109 7.20972 1.86639C7.68443 1.39168 8.32826 1.125 8.99959 1.125C9.67092 1.125 10.3148 1.39168 10.7895 1.86639C11.2642 2.34109 11.5308 2.98492 11.5308 3.65625V6.1875" stroke="#2E2D5A" strokeOpacity="0.68" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    )
}

export {Lock}