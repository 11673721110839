import React from 'react'

const ChatIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.5 8.12497H12.5" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.5 10.625H12.5" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.6929 15.3034L10.5359 17.2317C10.4804 17.3243 10.4019 17.4009 10.3079 17.4541C10.214 17.5072 10.1079 17.5352 10 17.5352C9.89207 17.5352 9.78598 17.5072 9.69206 17.4541C9.59814 17.4009 9.5196 17.3243 9.46407 17.2317L8.30706 15.3034C8.25154 15.2109 8.17299 15.1343 8.07907 15.0811C7.98515 15.0279 7.87906 15 7.77113 15H3.125C2.95924 15 2.80027 14.9341 2.68306 14.8169C2.56585 14.6997 2.5 14.5407 2.5 14.375V4.375C2.5 4.20924 2.56585 4.05027 2.68306 3.93306C2.80027 3.81585 2.95924 3.75 3.125 3.75H16.875C17.0408 3.75 17.1997 3.81585 17.3169 3.93306C17.4342 4.05027 17.5 4.20924 17.5 4.375V14.375C17.5 14.5408 17.4342 14.6997 17.3169 14.8169C17.1997 14.9342 17.0408 15 16.875 15L12.2289 15C12.1209 15 12.0149 15.0279 11.9209 15.0811C11.827 15.1343 11.7485 15.2109 11.6929 15.3034V15.3034Z" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
    )
}

export {ChatIcon}