import React from 'react'
import styled from 'styled-components'

const SmallText = ({children, margin = '0', weight, color, size = '12px'}) => {
  return (
    <StyledText size={size} margin={margin} weight={weight} color={color}>
      {children}
    </StyledText>
  )
}

const StyledText = styled.p`
  font-size: ${props => props.size};
  font-weight: ${props => props.weight};
  color: ${props => props.color || 'rgba(46, 45, 90, 0.68)'}; 

  margin: ${props => props.margin};
`


export {SmallText}