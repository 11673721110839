import styled from 'styled-components'

const Wrapper = styled.div`
  width: 30vw;
  margin-top: 2rem;
  padding: 1rem 2rem;
`

const Dashboard = styled.div`
  background: ${props => props.theme.colors.white};

  box-shadow: 1px 1px 30px 1px rgba(15, 17, 38, 0.05);
  border-radius: 12px;
`

const Resume = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 1rem 0;
  padding: 1rem;

  > div {
    display: flex;
    align-items: center;
  }
`

const Placement = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  background: ${props => props.theme.colors.lightGray};

  border-radius: 50%;

  font-size: ${props => props.theme.fontSizes.xs};

  margin-right: 1rem;
`

export {Wrapper, Dashboard, Resume, Placement}