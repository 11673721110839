import React from 'react'
import styled from 'styled-components'

const RegularText = ({children, margin = '0', style}) => {
  return (
    <StyledText margin={margin} style={style}>
      {children}
    </StyledText>
  )
}

const StyledText = styled.span`
  font-size: ${props => props.theme.fontSizes.sm};
  color:  ${props => props.theme.colors.dark};
  margin: ${props => props.margin};
`

export {RegularText}