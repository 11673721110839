import {useQuery, useMutation, useQueryClient} from 'react-query'
import {client} from '../utils/api-client'

const errors = [400, 401, 403, 404, 500]

const useFormularies = () => {
    const {data, status} = useQuery({
      queryKey: 'formularies',
      queryFn: () =>
        client(
            'super-admin/forms?order=asc&sortBy=publishedDate', 
            // {token: user.token}
        ).then(data => data.forms)
    })
    return {
      data,
      status
    }
}

const useFormsRanking = () => {
  const {data, status} = useQuery({
    queryKey: 'ranking',
    queryFn: () =>
      client(
          'forms-ranking', 
          // {token: user.token}
      ).then(data => data)
  })
  return {
    data,
    status
  }
}

const useAddNewFormulary = () =>  {
  const queryClient = useQueryClient()
  return useMutation(
    (data) => 
      client('super-admin/forms', {
        method: 'POST',
        data,
      }),
      {
        onSuccess: () => queryClient.invalidateQueries('formularies'),
        onError: () => queryClient.invalidateQueries('formularies'),
        onSettled: () => queryClient.invalidateQueries('formularies')
      }
  )
}

const useFormularyById = (formId) => {
    const {data, isLoading} = useQuery({
      queryKey: ['formulary', formId],
      queryFn: () =>
        client(
            `super-admin/forms/${formId}`, 
            // {token: user.token}
        ).then(data => data),
    })
    
    return {data, isLoading} ?? {}
}

const useIndexFormularyById = (formId) => {
  const {data, isLoading} = useQuery({
    queryKey: ['formulary', formId],
    queryFn: () =>
      client(
          `super-admin/forms/${formId}/index`, 
          // {token: user.token}
      ).then(data => data.form),
    
  })
  return {data, isLoading} ?? {}
}

const useEditFormularyStatus = (formId) =>  {
  const queryClient = useQueryClient()
  return useMutation(
    (data) => 
      client(`super-admin/forms/${formId}`, {
        method: 'PUT',
        data,
      }),
      {
        onSuccess: () => queryClient.invalidateQueries('formulary'),
        onError: () => queryClient.invalidateQueries('formulary'),
        onSettled: () => queryClient.invalidateQueries('formulary'),
      }
  )
}

const useAddQuestionByFormId = (formId) =>  {
  const queryClient = useQueryClient()
  return useMutation(
    data => 
      client(`super-admin/forms/${formId}/questions`, {
        method: 'POST',
        data,
      }),
      {
        onSuccess: () => queryClient.invalidateQueries('formulary'),
        onError: () => queryClient.invalidateQueries('formulary'),
        onSettled: () => queryClient.invalidateQueries('formulary')
      }
  )
}

const useRemoveQuestion = (formId) =>  {
  const queryClient = useQueryClient()
  return useMutation(
    questionId => 
      client(`super-admin/forms/${formId}/questions/${questionId}`, {
        method: 'DELETE'
      }),
      {
        onSuccess: () => queryClient.invalidateQueries('formulary'),
        onError: () => queryClient.invalidateQueries('formulary'),
        onSettled: () => queryClient.invalidateQueries('formulary'),
      }
  )
}

const useEditQuestion = (formId, questionId) =>  {
  const queryClient = useQueryClient()
  return useMutation(
    data => 
      client(`super-admin/forms/${formId}/questions/${questionId}`, {
        method: 'PUT',
        data,
      }),
      {
        onSuccess: () => queryClient.invalidateQueries('formulary'),
      }
  )
}

const useModules = () => {
    const {data} = useQuery({
      queryKey: 'modules',
      queryFn: () =>
        client(
            'super-admin/modules', 
            // {token: user.token}
        ).then(data => data)
    })
    return data ?? []
}

export {
    useFormularies, 
    useFormularyById,
    useAddQuestionByFormId, 
    useModules,
    useRemoveQuestion,
    useEditQuestion,
    useAddNewFormulary,
    useEditFormularyStatus,
    useIndexFormularyById,
    useFormsRanking
}