const status = {
  rejected: 'Rechazada',
  requested: 'Solicitud',
  accepted: 'Aceptada'
}

const filterOrgs = (orgs = [], searchVal = '') => {
  return  [...orgs].filter(el => 
    el.name?.toLowerCase().includes(searchVal.toLowerCase()) ||
    el.location?.toLowerCase().includes(searchVal.toLowerCase()) ||
    status[el.status].toLowerCase().includes(searchVal.toLowerCase())
  )
}
  
  export {filterOrgs}