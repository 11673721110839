import React from 'react'
import styled from 'styled-components'
import {getForms} from '../../helpers/calculate-forms-amount'
import {Overview, Formularies, Ranking} from './components/index'
import {useFormsRanking} from 'hooks/formularies'
import { Spinner } from 'shared/assets/svgs'

const Desktop = () => {
  const {data: formsRanking, status: formsRankingStatus } = useFormsRanking()
  
  return (
    <>
    {
      formsRankingStatus === 'loading'
        ? <Spinner />
        : <Wrapper>
          <div>
            {/* <Overview 
              organizations={organizations} 
              filteredOrgs={org => org.status.name === 'accepted'} 
              title='Últimas organizaciones registradas' 
            />
            <Overview 
              organizations={organizations} 
              filteredOrgs={org => org.status.name === 'requested'} 
              title='Invitaciones pendientes' 
            /> */}
          </div>
          <div>
            <Formularies   
              formsCompleted={formsRanking?.form?.nCompleted} 
              formsInCourse={formsRanking.form?.nInProgress}
            />
            <Ranking organizations={formsRanking?.form?.organizations} />
          </div>
        </Wrapper>
    }
    </>
  )
}

const Wrapper = styled.div`

  > div {
    display: flex;
    justify-content: space-between;
  }
`

export {Desktop}