import React from 'react'
import Modal from 'styled-react-modal'

const CustomModal = ({children, isOpen, toggleModal, width, height, background, justifyCont='center', alignItems='center', style}) => {
  return (
    <StyledModal
      isOpen={isOpen}
      onBackgroundClick={toggleModal}
      onEscapeKeydown={toggleModal}
      width={width}
      height={height}
      background={background}
      justifyCont={justifyCont}
      alignItems={alignItems}
      style={style}
    >
      {children}
    </StyledModal>
  )
}

const StyledModal = Modal.styled`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};
  display: flex;
  align-items: ${props => props.alignItems};
  justify-content:  ${props => props.justifyCont};
  background-color: ${props => props.background || 'white'};
  border-radius: 20px;
`

export {CustomModal}