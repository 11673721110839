import {useQuery, useMutation, useQueryClient} from 'react-query'
import {client} from '../utils/api-client'

const useOrganizationsInvitations = () => {
  const {data, status} = useQuery({
    queryKey: 'organizations-invitations',
    queryFn: () =>
      client(
        'super-admin/organizations-registers?order=desc&sortBy=statusChangeDate', 
      ).then(data => data.organizationsRegisters),
        })
  return {
    data,
    status
  }
}

const useUpdateOrganizationInvitation = (onError) =>  {
  const queryClient = useQueryClient()
  return useMutation(
    (data) => 
      client(`super-admin/organizations-registers/${data.id}`, {
        method: 'PUT',
        data: data.status,
      }),
      {
        onSuccess: () => queryClient.invalidateQueries('organizations-invitations'),
        onError: onError
      }
  )
}

const useResendOrganizationAcceptanceEmail = () =>  {
  const queryClient = useQueryClient()
  return useMutation(
    (orgId) => 
      client(`super-admin/organizations-registers/${orgId}/resend-acceptance-email`, {
        method: 'POST'
      }),
      {
        onSuccess: () => queryClient.invalidateQueries('organizations-invitations'),
        onError: (err) => {throw new Error(err)}
      }
  )
}

export {useOrganizationsInvitations, useUpdateOrganizationInvitation, useResendOrganizationAcceptanceEmail}