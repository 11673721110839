import React from 'react'
import {ThemeProvider} from "styled-components";
import {theme} from 'utils/theme'
import GlobalStyles from 'utils/globalStyles';
import {UnauthenticatedApp} from 'unauthenticated-app'
import {AuthenticatedApp} from 'authenticated-app'
import {useAuth} from 'auth/context'
import {QueryClient, QueryClientProvider} from 'react-query';

import {ErrorBoundary} from 'react-error-boundary'
import {ErrorFallback} from 'shared/components/ErrorFallback'

const errors = [401, 403, 404, 500]

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      useErrorBoundary: (error) => errors.includes(parseInt(error.response?.status))
    },
  },
})


const App = () => {
  const {user} = useAuth()
  const token = localStorage.getItem('__auth_provider_token__')

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {token 
          ? <ErrorBoundary FallbackComponent={ErrorFallback}><AuthenticatedApp /></ErrorBoundary>
          : <UnauthenticatedApp />
        }
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App;
