const questionMethods = () => {
  return {
    add: (e, state, setError, add, setOpenModalType, setErrors) => {
      e.preventDefault()
      const { title, type, module } = state
      const question = {...state}

      if(!title || !type || !module) {
        setError(true)
        return
      }

      if(state.type === 'text' && state?.options?.length > 0) {
        setErrors('text-with-options')
        return
      }

      if(state?.options?.length <= 1) {
        setErrors('less-than-two-options')
        return
      }

      try {
        add(question)
      }
      catch(err) {
        setErrors('something-went-wrong')
      }
      
      setOpenModalType('')
      setErrors('')
    },
    edit: (e, editQuestionFields, edit, setOpenModalType, setErrors) => {
      e.preventDefault()

      // if type of the question is text it can't have options so it should throw an error and show it to the user.
      if(editQuestionFields.type === 'text' && editQuestionFields?.options?.length > 0) {
        setErrors('text-with-options')
        return
      }
  
      try {
        edit(editQuestionFields)
      }
      catch(err) {
        setErrors('something-went-wrong')
      }
      setOpenModalType('')
      setErrors('')
    },
    remove: (e, id, remove, setErrors) => {
      e.preventDefault()

      try {
        remove(id)
      }
      catch(err) {
        setErrors('something-went-wrong')
      }
    }
  }
}

export {questionMethods}