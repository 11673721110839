import React from 'react'
import styled from 'styled-components'

const Label = ({children}) => {
  return (
    <StyledLabel>
      {children}
    </StyledLabel>
  )
}

const StyledLabel = styled.label`
  margin: 1rem 0;
  font-size: ${props => props.theme.fontSizes.base};
  color: rgba(46, 45, 90, 0.68);
`

export {Label}