import React from 'react'

const Toggler = () => {
    return (
        <svg width="43" height="21" viewBox="0 0 43 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.758523 15H2.60369V12.1705H4.15483C6.16193 12.1705 7.32955 10.973 7.32955 9.23011C7.32955 7.49574 6.18324 6.27273 4.2017 6.27273H0.758523V15ZM2.60369 10.6918V7.78125H3.84801C4.91335 7.78125 5.42898 8.3608 5.42898 9.23011C5.42898 10.0952 4.91335 10.6918 3.85653 10.6918H2.60369ZM8.19567 7.79403H10.8675V15H12.6914V7.79403H15.3633V6.27273H8.19567V7.79403ZM21.2781 8.78267H23.0465C23.021 7.22727 21.7425 6.15341 19.7994 6.15341C17.886 6.15341 16.484 7.21023 16.4925 8.79545C16.4883 10.0824 17.396 10.8196 18.8704 11.1733L19.8207 11.4119C20.771 11.642 21.2994 11.9148 21.3036 12.5028C21.2994 13.142 20.6942 13.5767 19.7567 13.5767C18.7979 13.5767 18.1076 13.1335 18.0479 12.2599H16.2624C16.3093 14.1477 17.6602 15.1236 19.7781 15.1236C21.9087 15.1236 23.1616 14.1051 23.1658 12.5071C23.1616 11.054 22.0664 10.2827 20.5494 9.94176L19.7653 9.75426C19.0067 9.57955 18.3718 9.2983 18.3846 8.67188C18.3846 8.10938 18.8832 7.69602 19.7866 7.69602C20.6687 7.69602 21.2099 8.09659 21.2781 8.78267Z" fill="#2E2D5A" fillOpacity="0.68"/>
<path d="M33.25 7.5L37 3.75L40.75 7.5" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M40.75 13.5L37 17.25L33.25 13.5" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    )
}

export {Toggler}