import React, {useState} from 'react'
import {Wrapper, Bar, LiWrapper, HeadersWrapper, Button} from './styles'
import {CaretUp, CaretDown, Spinner} from 'shared/assets/svgs/index'
import {Search, Subtitle, SmallText, Badge, ListItem} from 'shared/components/index'
import {OrganizationDetail} from './Detail'
import {filterOrgs} from './helpers/filter-orgs'
import {usePaginator} from 'hooks/use-paginator'
import {useOrganizationsInvitations, useResendOrganizationAcceptanceEmail, useUpdateOrganizationInvitation} from 'hooks/invitations'
import { ErrorMessage } from 'pages/Formularies/Question/styles'

const STATUS_TYPES = {
  rejected: {
    background: 'rgba(230, 20, 139, 0.2)',
    color: '#E6148B',
    status: 'Rechazada',
    hasApprove: true,
    hasRejected: false
  },
  requested: {
    background: '#D2C6DC',
    color: '#6C3F98',
    status: 'Solicitud',
    hasApprove: true,
    hasRejected: true
  },
  accepted: {
    background: '#BCFFAC',
    color: '#03A51E',
    status: 'Aprobada',
    hasApprove: false,
    hasRejected: false
  }
}

const Invitations = () => {
  const [isOpenOrgDetailsModal, setIsOpenOrgDetailsModal] = useState(false)
  const [searchVal, setSearchVal] = useState('')
  const [organization, setOrganization] = useState({})
  const [orgId, setOrgId] = useState()
  const [error, setError] = useState('')
  const {data: organizations, orgsStatus} = useOrganizationsInvitations()

  const orgs = filterOrgs(organizations, searchVal)
  const {items, pages, pagesList, setPageNumber} = usePaginator(orgs, 10)

  const onError = () => {
    setError('email-or-organization-already-exists')
  }

  const {mutate: action, isLoading, isError} = useUpdateOrganizationInvitation(onError)
  const {mutate: resend, isLoading: isResendLoading} = useResendOrganizationAcceptanceEmail()

  const handleActions = (orgId, status) => {
    console.log(orgId, 'orgId in handle actions')
    setOrgId(orgId)
    action({id: orgId, status})

    if(!isError) {
      setError('')
    }
  }

  const resendEmail = (orgId) => {
    try {
      resend(orgId)
    } catch(err) {
      throw new Error(err)
    }
  }

  const handleOrganizationDetailModal = (organization) => {
    setOrganization(organization)
    setIsOpenOrgDetailsModal(true)
  }

  return (
    <Wrapper>
      <div>
        <Bar>
          <Subtitle>
            Invitaciones
          </Subtitle>
          <Search
            width='30%'
            placeholder='Buscar por organización, país, o estado'
            isSearch={true}
            bg='#F9F8FA'
            padding='1rem'
            id='search'
            name='search'
            onChange={(e) => setSearchVal(e.target.value)}
          />
        </Bar>
        <ul style={{padding: '0'}}>
          <HeadersWrapper>
            <div>
              Organización
            </div>
            <button 
              style={{display: 'flex', fontSize: '14px', fontWeight: '600', color: 'rgba(46, 45, 90, 0.68)', cursor: 'pointer', background: 'none', width: 'fit-content', border: 'none'}}
              // onClick={() => setSortedByDate(prevState => !prevState)}
            >
              <span>
                Fecha
              </span>
              <div style={{margin: '-0.5rem 0.5rem'}}>
                <div>
                  <CaretUp />
                </div>
                <div>
                  <CaretDown />
                </div>
              </div>
            </button>
            <div>
              País
            </div>
            <div>
              Estado
            </div>
            <div>
              Acciones
            </div>
          </HeadersWrapper>
          {items
            .map(org => (
            <LiWrapper 
              key={org.id} 
              style={{borderBottom: '1px solid rgba(46, 45, 90, 0.05)'}}
              onClick={() => handleOrganizationDetailModal(org)}
            >
              <ListItem 
                padding='1rem 0'
                fontSize='sm'
                color='#3D4852'
              >
                {org.name}
              </ListItem>
              <ListItem 
                padding='1rem 0'
                fontSize='sm'
                color='#3D4852'
              >
                {org.statusChangeDate.slice(0, 10)}
              </ListItem>
              <ListItem 
                padding='1rem 0'
                fontSize='sm'
                color='#3D4852'
              >
                {org.location}
              </ListItem>
              <Badge
                background={STATUS_TYPES[org.status].background}
                borderRadius='100px'
                margin={0}
                padding='0.5rem 1rem'
                style={{width: 'fit-content', height: 'fit-content', margin: 'auto', marginTop: '0.75rem'}}
              >
                <SmallText color={STATUS_TYPES[org.status].color}>
                  {STATUS_TYPES[org.status].status}
                </SmallText>      
              </Badge>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Button
                  background='#36B118'
                  br='full'
                  px='xl'
                  py='md'
                  style={{cursor: 'pointer'}}
                  onClick={() => handleActions(org.id, {status: 'accepted'})}
                  disabled={!STATUS_TYPES[org.status].hasApprove}
                >
                  <SmallText color='white'>{orgId === org.id && isLoading ? <Spinner /> : 'Aprobar'}</SmallText>
                </Button>
                <Button
                  background='#F19305'
                  style={{cursor: 'pointer'}}
                  onClick={() => resendEmail(org.id)}
                >
                  <SmallText color='white'>{orgId === org.id && isResendLoading ? <Spinner /> : 'Reenviar'}</SmallText>
                </Button>
                <Button
                  background='#E6148B'
                  style={{cursor: 'pointer'}}
                  onClick={() => handleActions(org.id, {status: 'rejected'})}
                  disabled={!STATUS_TYPES[org.status].hasRejected}
                >
                  <SmallText color='white'>{orgId === org.id && isLoading ? <Spinner /> : 'Rechazar'}</SmallText>
                </Button>
              </div>
            </LiWrapper>                 
          ))}
          {error === 'email-or-organization-already-exists' && <ErrorMessage>El email o la organización ya existen.</ErrorMessage>}
        </ul>
      </div>

      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
        {pagesList.map(lof => (
          <Badge
            key={lof}
            background='rgba(108, 63, 152, 0.2)'
            color='#6C3F98'
            fontWeight='bold'
            onClick={() => setPageNumber(lof)}
            style={{cursor: 'pointer', textAlign: 'center'}}
          >
            {lof}
          </Badge>
        ))}
      </div>

      {isOpenOrgDetailsModal 
        ? <OrganizationDetail 
            isOpenOrgDetailsModal={isOpenOrgDetailsModal} 
            setIsOpenOrgDetailsModal={setIsOpenOrgDetailsModal}
            organization={organization}
          /> 
        : null
      }

    </Wrapper>
  )
}

export {Invitations}