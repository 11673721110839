import React from 'react'
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import {Login} from './pages/Login'
import {useAuth} from './auth/context'

const UnauthenticatedApp = () => {
  const {login} = useAuth()
  return (
    <>
      <Router>
        <Routes>
          <Route exact path='login' element={<Login login={login} />}></Route>
          <Route path="*" element={<Navigate to="/login" />}/>
        </Routes>
      </Router>
    </>
  );
}

export {UnauthenticatedApp}
