import React, {useState} from 'react'
import {StyledInput, ErrorMessage} from '../../styles'
import {SmallText} from 'shared/components/index'
import {Button} from 'shared/components/Button'
import {Check} from 'shared/assets/svgs/index'

const Options = ({state, change, actionType, setEditQuestionFields}) => {
  const [description, setDescription] = useState('')
  const [score, setScore] = useState(0)
  const [optionsError, setOptionsError] = useState(false)

  const addOption = (e) => {
    e.preventDefault()

    if(!description) {
      return setOptionsError(true)
    } else {
      setOptionsError(false)
    }

    change(
      actionType,
      {options: [...state?.options, 
      {description: description, score: parseFloat(score)}]}
    )
    setEditQuestionFields(oldState => ({...oldState, options: [...state?.options, {description: description, score: score}]}))
  }

  return (
    <>
      <div 
        style={{display: 'flex', marginTop: '1rem', padding: '0'}}
      >
        <StyledInput 
          type='text' 
          placeholder='Agrega una respuesta...'
          name='description'
          onChange={(e) => setDescription(e.target.value)}
          required={state?.options?.length === 0}
        />
        <div>
          <SmallText>
            Pts
          </SmallText>
          <input 
            type='number' 
            name='score'
            step='any'
            onChange={(e) => setScore(parseFloat(e.target.value).toFixed(2))}
            required={actionType === 'add'}
            style={{margin: '0 1rem 0 0', padding: '0.5rem 0.25rem', border: '1px solid rgba(46, 45, 90, 0.68)', borderRadius: '8px'}}
          />
        </div>
        <Button 
          background='#6C3F98'
          borderRadius='100px'
          padding='0.75rem'
          onClick={addOption}
        >
          <Check />
        </Button>
      </div>
      {optionsError ? <ErrorMessage>Recordá que los campos descripción y puntaje son obligatorios</ErrorMessage> : null}
    </>
  )
}

export {Options}