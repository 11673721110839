import React, {useReducer, useState} from 'react'
import styled from 'styled-components'
import {
  useLookups,
  useLookupsWithOrganizations,
  useAddOrganization
} from 'hooks/organizations'
import {Button} from 'shared/components/Button'
import {Label, Input, Select, Title} from 'shared/components/index'
import {addOrgReducer} from './reducer'
import { Spinner } from 'shared/assets/svgs'
import {Modal} from 'shared/components/index'

const defaultState = {
  name: '',
  type: '',
  location: '',
  employeesNumber: 0,
  accessionLetter: '',
  representative: {
    name: '',
    pronoun: '',
    emailAddress: '',
    contactNumber: '',
    department: ''
  }
}

const AddOrganization = ({isOpenAddOrganizationModal, setIsOpenAddOrganizationModal}) => {
  const [error, setError] = useState('')
  const {data: departments, status: isDepartmentsLoading} = useLookupsWithOrganizations('departments')
  const {data: locations, status: isLocationsLoading} = useLookups('locations')
  const {data: types, status: isTypesLoading} = useLookupsWithOrganizations('types')
  const {data: pronouns, status: isPronounsLoading} = useLookups('pronouns')
  const {data: sectors, status: isSectorsLoading} = useLookupsWithOrganizations('sectors')

  const [state, dispatch] = useReducer(addOrgReducer, defaultState)
  const change = (field, value) => dispatch({type: 'mutate', field, payload: value})

  const {mutate: add} = useAddOrganization()
  const handleSubmit = (e) => {
    e.preventDefault()
    const {name, type, location, employeesNumber, department, representative} = state
    const {name: representativeName, pronoun, emailAddress, contactNumber} = representative

    if(!name, !type, !location, !employeesNumber, !department, !representativeName, !pronoun, !emailAddress, !contactNumber) {
      setError('has-empty-fields')
      return
    }

    try {
      add(state)
    } catch(err) {
      throw new Error(err)
    }
    setIsOpenAddOrganizationModal(false)

  }

  if(isLocationsLoading || isPronounsLoading || isTypesLoading || isDepartmentsLoading) {
    return <Spinner />
  }
  
  return (
    <>
     <Modal
        isOpen={isOpenAddOrganizationModal}
        toggleModal={setIsOpenAddOrganizationModal}
        width='52.5rem'
        height='45.5rem'
      >
        <form onSubmit={handleSubmit} style={{margin: '2rem'}}>
          <Title margin={'1rem 0'}>Agregar organización</Title>
            <Wrapper>
            <div>
              <Label htmlFor='organization-name'>Nombre organización</Label>
              <Input 
                required
                name='organization-name' 
                type='text'
                value={state.name}
                onChange={(e) => change('name', e.target.value)}
              />
            </div>
            <div>
                <Label htmlFor='types'>Tipo de industria</Label>
                <Select 
                name='types'
                value={state.type}
                onChange={(e) => change('type', e.target.value)}
                >
                  <option       
                    value=''
                    selected
                    disabled
                  >
                    Seleccionar
                  </option>
                {types?.organizationTypes.map(type => (
                    <option 
                    key={type.name} 
                    value={type.name}
                    >
                    {type?.name}
                    </option>
                ))}      
                </Select>
            </div>
            </Wrapper>

            <Wrapper>
            <div>
                <Label htmlFor='locations'>País</Label>
                <Select 
                name='locations'
                value={state.location}
                onChange={(e) => change('location', e.target.value)}
                >
                  <option       
                    value=''
                    selected
                    disabled
                  >
                    Seleccionar
                  </option>
                {locations?.locations.map(location => (
                    <option 
                    key={location.name} 
                    value={location.name}
                    >
                    {location.name}
                    </option>
                ))} 
                </Select>
            </div>
            </Wrapper>

            <Wrapper>
            <div style={{width: '42.5%'}}>
                <Label htmlFor='employeesNumber'>Cantidad de empleadxs</Label>
              <Input 
                required
                name='employeesNumber' 
                type='number'
                value={state.employeesNumber}
                onChange={(e) => change('employeesNumber', parseInt(e.target.value))}
              />
            </div>
            <div>
                <Label htmlFor='types'>Sector</Label>
                <Select 
                name='sectors'
                value={state.sector}
                onChange={(e) => change('sector', e.target.value)}
                >
                  <option       
                    value=''
                    selected
                    disabled
                  >
                    Seleccionar
                  </option>
                {sectors?.organizationSectors?.map(sector => (
                    <option 
                    key={sector.name} 
                    value={sector.name}
                    >
                    {sector?.name}
                    </option>
                ))}      
                </Select>
            </div>
            </Wrapper>

            <Wrapper>
            <div>
              <Label htmlFor='accessionLetter'>Carta firmada de adhesión</Label>
              <Input 
                required
                name='accessionLetter' 
                type='text'
                value={state?.accessionLetter}
                onChange={(e) => change('accessionLetter', e.target.value)}
              />
            </div>
            </Wrapper>

            <Wrapper>
              <div>
                <Label htmlFor='representative'>Responsable plataforma</Label>
                <Input 
                  required
                  name='representative' 
                  type='text'
                  value={state.representative?.name}
                  onChange={(e) => change('representative', {...state?.representative, name: e.target.value})}
                />
              </div>
              <div>
                  <Label htmlFor='pronounce'>Pronombre</Label>
                  <Select 
                  name='pronoun'
                  value={state.representative?.pronoun}
                  onChange={(e) => change('representative', {...state?.representative, pronoun: e.target.value})}
                  >
                    <option       
                      value=''
                      selected
                      disabled
                    >
                      Seleccionar
                    </option>
                  {pronouns?.pronouns.map(pronoun => (
                      <option 
                        key={pronoun.name} 
                        value={pronoun.name} 
                      >
                      {pronoun.name}
                      </option>
                  ))} 
                  </Select>
              </div>
            </Wrapper>

            <Wrapper>
            <div>
                <Label htmlFor='contact'>Contacto</Label>
                <Input 
                  required
                  name='contact' 
                  type='text'
                  value={state.representative?.emailAddress}
                  onChange={(e) => change('representative', {...state?.representative, emailAddress: e.target.value})}
                />
            </div>
            <div>
              <Label htmlFor='phone'>Teléfono</Label>
              <Input 
                required
                name='phone' 
                type="number" 
                pattern=".{10,10}"
                value={state.representative?.contactNumber}
                onChange={(e) => change('representative', {...state?.representative, contactNumber: e.target.value})}
              />
            </div>
            </Wrapper>

            <Wrapper>
            <div style={{width: '42.5%'}}>
                <Label htmlFor='operationArea'>Área</Label>
                <Select 
                name='operationArea'
                value={state.department}
                onChange={(e) => change('representative', {...state?.representative, department: e.target.value})}
                >
                  <option       
                    value=''
                    selected
                    disabled
                  >
                    Seleccionar
                  </option>
                {departments?.organizationDepartments.map(department => (
                    <option 
                    key={department.name} 
                    value={department.name}
                    >
                    {department.name}
                    </option>
                ))} 
                </Select>
            </div>
            </Wrapper>
            {error && <span style={{color: 'red', fontSize: '14px', margin: '1rem 0'}}>Todos los campos son obligatorios.</span>}
            <Wrapper>
            <Button 
              background='#00B9B4' fontSize='sm' fontWeight='bold' padding='0.75rem' style={{margin: '0.25rem'}}
              type='submit'
            >
              Agregar organización
            </Button>
            <Button 
              bg='#FFFFFF' fontSize='sm' fontWeight='bold' padding='0.75rem' style={{margin: '0.25rem'}}
              onClick={() => setIsOpenAddOrganizationModal(false)}
            >
                Cancelar
            </Button>
            </Wrapper>        
          </form>
        
      </Modal>
    </>
  )
}

const Wrapper = styled.main`
  display: flex;

  width: 100%;

  > div {
    width: 100%;
    margin-right: 4rem;
  }
`

export {AddOrganization}