

import React from 'react'

const Department = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 26.9979H30" stroke="#2E2D5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.9993 26.9979V4.99792C17.9993 4.73271 17.8939 4.47835 17.7064 4.29082C17.5188 4.10328 17.2645 3.99792 16.9993 3.99792H4.99927C4.73405 3.99792 4.4797 4.10328 4.29216 4.29082C4.10462 4.47835 3.99927 4.73271 3.99927 4.99792V26.9979" stroke="#2E2D5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M27.9993 26.9979V12.9979C27.9993 12.7327 27.8939 12.4784 27.7064 12.2908C27.5188 12.1033 27.2645 11.9979 26.9993 11.9979H17.9993" stroke="#2E2D5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.99927 8.99792H11.9993" stroke="#2E2D5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.99927 16.9979H13.9993" stroke="#2E2D5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.99927 21.9979H11.9993" stroke="#2E2D5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.9993 21.9979H23.9993" stroke="#2E2D5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.9993 16.9979H23.9993" stroke="#2E2D5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    )
}

export {Department}