
import React from 'react'

const User = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 11.25C11.4853 11.25 13.5 9.23528 13.5 6.75C13.5 4.26472 11.4853 2.25 9 2.25C6.51472 2.25 4.5 4.26472 4.5 6.75C4.5 9.23528 6.51472 11.25 9 11.25Z" stroke="#2E2D5A" strokeOpacity="0.68" strokeMiterlimit="10"/>
            <path d="M2.17896 15.1868C2.87052 13.9898 3.86492 12.9959 5.06225 12.3048C6.25958 11.6138 7.61768 11.25 9.00012 11.25C10.3826 11.25 11.7407 11.6138 12.938 12.3049C14.1353 12.996 15.1297 13.9899 15.8212 15.187" stroke="#2E2D5A" strokeOpacity="0.68" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export {User}