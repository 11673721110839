import React from 'react'

const MailIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.5 4.375H17.5V15C17.5 15.1658 17.4342 15.3247 17.3169 15.4419C17.1997 15.5592 17.0408 15.625 16.875 15.625H3.125C2.95924 15.625 2.80027 15.5592 2.68306 15.4419C2.56585 15.3247 2.5 15.1658 2.5 15V4.375Z" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.5 4.375L10 11.25L2.5 4.375" stroke="#2E2D5A" strokeOpacity="0.68" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    )
}

export {MailIcon}
