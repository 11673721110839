import {useQuery, useMutation, useQueryClient} from 'react-query'
import {client} from '../utils/api-client'

const useOrganizations = () => {
  const {data, status} = useQuery({
    queryKey: 'organizations',
    queryFn: () =>
      client(
          'super-admin/organizations?order=desc&sortBy=createdDate', 
      ).then(data => data.organizations),
        })
  return {
    data,
    status
  }
}

const useOrganizationById = (orgId) => {
    const {data, isOrganizationLoading} = useQuery({
      queryKey: ['organization', orgId],
      queryFn: () =>
        client(
            `super-admin/organizations/${orgId}`, 
        ).then(data => data.organization),
      cacheTime: 0
      })
    return {
      data: data,
      isOrganizationLoading
    }
}

const useAddOrganization = () =>  {
  const queryClient = useQueryClient()
  return useMutation(
    (data) => 
      client('super-admin/organizations', {
        method: 'POST',
        data,
      }),
      {
        onSuccess: () => queryClient.invalidateQueries('organizations'),
        onError: () => queryClient.invalidateQueries('organizations'),
        onSettled: () => queryClient.invalidateQueries('organizations')
      }
  )
}

const useOrganizationProgress = (orgId) => {
  const {data: organizationProgress, isLoading: isOrganizationProgressLoading} = useQuery({
    queryKey: ['organization', orgId],
    queryFn: () =>
      client(
          `super-admin/organizations/${orgId}/progress`
      ).then(data => data.organization),
        })
  return {
    organizationProgress: organizationProgress ?? {},
    isLoading: isOrganizationProgressLoading
  }
}

const useUpdateOrganizations = (orgId) =>  {
  const queryClient = useQueryClient()
  return useMutation(
    (updates) => 
      client(`super-admin/organizations/${orgId}`, {
        method: 'PUT',
        data: updates,
      }),
      {
        onSuccess: () => queryClient.invalidateQueries('organizations')
      }
  )
}

const useResetOrganizationPassword = (orgId) =>  {
  const queryClient = useQueryClient()
  return useMutation(
    (updates) => 
      client(`super-admin/organizations/${orgId}/reset-password`, {
        method: 'POST',
        data: updates,
      }),
      {
        onSuccess: () => queryClient.invalidateQueries('organizations')
      }
  )
}

const useResendOrganizationAcceptanceEmail = (orgId) =>  {
  const queryClient = useQueryClient()
  return useMutation(
    (updates) => 
      client(`super-admin/organizations/${orgId}/reset-password`, {
        method: 'POST',
        data: updates,
      }),
      {
        onSuccess: () => queryClient.invalidateQueries('organizations')
      }
  )
}

const useLookupsWithOrganizations = (lookup) => {
    const {data, isLoading} = useQuery({
      queryKey: lookup,
      queryFn: () =>
        client(
            `super-admin/lookups/organizations/${lookup}`, 

        ).then(data => data),
      refetchOnMount: false,
      refetchOnWindowFocus: false
    },
    )
    return {
      data: data ?? [],
      status: isLoading
    }
}

const useLookups = (lookup) => {
    const {data, isLoading} = useQuery({
      queryKey: lookup,
      queryFn: () =>
        client(
            `super-admin/lookups/${lookup}`, 

        ).then(data => data),
        refetchOnMount: false,
        refetchOnWindowFocus: false
    },
    )
    return {
      data: data ?? [],
      status: isLoading
    }
}

export {
    useOrganizations, 
    useOrganizationById, 
    useUpdateOrganizations,
    useOrganizationProgress,
    useLookupsWithOrganizations,
    useLookups,
    useAddOrganization,
    useResetOrganizationPassword
}