import React, {useState} from 'react'
import {Wrapper, Bar, LiWrapper, StyledLink} from './styles'
import {useOrganizations} from 'hooks/organizations'
import {Badge, Button, ListItem} from 'shared/components/index'
import {Search} from 'shared/components/index'
import {Accionar, Chat} from 'shared/assets/svgs/OrganizationsPageIcons/index'
import {Pencil} from 'shared/assets/svgs/index'
import {OrganizationDetail} from './Detail'
import {AddOrganization} from './Add'
import {sortAndFilterOrgs} from 'helpers/sort-and-filter-orgs'
import {usePaginator} from 'hooks/use-paginator'

const orgIcons = {
  accionar: <Accionar />
}

const Organizations = () => {
  const [isOpenOrgDetailsModal, setIsOpenOrgDetailsModal] = useState(false)
  const [isOpenAddOrganizationModal, setIsOpenAddOrganizationModal] = useState(false)
  const [searchVal, setSearchVal] = useState('')
  const [orgId, setOrgId] = useState()
  const [org, setOrg] = useState()
  const {data: orgs, orgsStatus} = useOrganizations()

  const organizations = sortAndFilterOrgs(orgs, searchVal, false)

  const {items, pages, pagesList, setPageNumber} = usePaginator(organizations, 10)

  const actionOnOrgDetails = (bool, org) => {
    setIsOpenOrgDetailsModal(bool)
    // setOrgId(id)
    setOrg(org)
  }

  return (
    <Wrapper>
      <div>
        <Bar>
          <Search
            width='75%'
            placeholder='Buscar nombre de la organización'
            isSearch={true}
            bg='#F9F8FA'
            padding='1rem'
            id='search'
            name='search'
            onChange={(e) => setSearchVal(e.target.value)}
          />
          <Button
            background='#F9F8FA'
            color='#3D4852'
            fontSize='sm'
            fontWeight='bold'
            padding='0.75rem 0'
            style={{width: '25%', marginLeft: '2rem'}}
            onClick={() => setIsOpenAddOrganizationModal(true)}
          >
            + Agregar organización
          </Button>
        </Bar>
        <ul style={{padding: '0'}}>
          {items
            .map(org => (
            <LiWrapper key={org.id} style={{borderBottom: '1px solid rgba(46, 45, 90, 0.05)'}}>
              <ListItem 
                padding='1rem 0'
                fontSize='base'
                color='#3D4852'
              >
                <span style={{margin: '0 0.25rem'}}>
                  {orgIcons[org.icon]}
                </span> 
                  {org.name}
              </ListItem>
              <div style={{display: 'flex'}}>
                <StyledLink
                  to={{
                    pathname: `edit/${org.id}`
                  }}
                >
                  <Pencil />
                </StyledLink> 
                <Button
                  background='#F9F8FA'
                  borderRadius='100px'
                  padding='0 0.75rem'
                  onClick={() => actionOnOrgDetails(true, org)}
                >
                  <Chat />
                </Button>
              </div>
            </LiWrapper>                 
          ))}
        </ul>
      </div>

      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
        {pagesList.map(lof => (
          <Badge
            key={lof}
            background='rgba(108, 63, 152, 0.2)'
            color='#6C3F98'
            fontWeight='bold'
            onClick={() => setPageNumber(lof)}
            style={{cursor: 'pointer', textAlign: 'center'}}
          >
            {lof}
          </Badge>
        ))}
      </div>

      {isOpenOrgDetailsModal 
        ? <OrganizationDetail 
            isOpenOrgDetailsModal={isOpenOrgDetailsModal} 
            setIsOpenOrgDetailsModal={setIsOpenOrgDetailsModal}
            organization={org}
          /> 
        : null
      }

      {isOpenAddOrganizationModal 
        ? <AddOrganization 
            isOpenAddOrganizationModal={isOpenAddOrganizationModal} 
            setIsOpenAddOrganizationModal={setIsOpenAddOrganizationModal}
          /> 
        : null
      }

    </Wrapper>
  )
}

export {Organizations}