import React from 'react'
import {Bar, Completed} from './styles'

const ProgressBar = ({amount = 35, total = 67, completedBg, bg}) => {
  const calculatePerc = () => {
    return parseInt((amount * 100) / total) || 0
  }

  return (
    <Bar bg={bg}>
      <Completed completedBg={completedBg} width={calculatePerc()} />
    </Bar>
  )
}

export {ProgressBar}